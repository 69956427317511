<template>
    <RouterView />
    <n-footer>
        <div class="footer">
            <n-grid cols="3" item-responsive>
                <n-gi span="3 600:1">
                    <n-space vertical>
                        <n-h5>
                            Dr Vinit Agrawal
                        </n-h5>
                        <n-h5>
                            AskForCure LLC
                        </n-h5>
                        <n-text>

                        </n-text>
                    </n-space>
                </n-gi>
                <n-gi span="3 600:1">
                    <n-space vertical>
                        <n-h5>
                            Links
                        </n-h5>
                        <n-a href="/privacy-policy">
                            Privacy Policy
                        </n-a>
                        <n-a href="/terms-of-service">
                            Terms of Service
                        </n-a>
                    </n-space>
                </n-gi>
                <n-gi span="3 600:1">
                    <n-space vertical>
                        <n-h5>
                            Contact Us
                        </n-h5>
                        <n-a href="tel:+17653278378">
                            765-327-8378
                        </n-a>
                        <n-text>
                            <a href="mailto:support@askforcure.com">support@askforcure.com</a>
                        </n-text>
                    </n-space>
                </n-gi>
            </n-grid>
            <n-grid cols="1" style="padding-top: 2rem;">
                <n-gi>&copy;Copyright 2024 Ask For Cure - All Rights Reserved.</n-gi>
            </n-grid>
        </div>
    </n-footer>
    <n-modal v-model:show="showModal" preset="dialog" title="Dialog">
        <template #icon>
            <n-icon>
                <CheckmarkCircle24Filled color="green" v-if="messageType === 'success'" />
                <ErrorCircle24Filled color="#0659ad" v-if="messageType === 'info'" />
                <DismissCircle24Filled color="#9a0033" v-if="messageType === 'error'" />
                <Warning24Filled color="#f8ad21" v-if="messageType === 'warning'" />
            </n-icon>
        </template>
        <template #header>
            <div>Message</div>
        </template>
        <div style="padding-top: 1rem;">{{ notificationMessage }}</div>
        <template #action>
            <n-space>
                <n-button @click="() => {showModal = false;}" tertiary :type="messageType">Okay</n-button>
            </n-space>
        </template>
    </n-modal>
</template>

<script setup lang="js">
import { computed, h, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { useMessage, useModal } from "naive-ui";
import { CheckmarkCircle24Filled, DismissCircle24Filled, ErrorCircle24Filled, Warning24Filled } from "@vicons/fluent";
import LandingModal from "../components/LandingModal.vue";


const store = useStore();
const messageBox = useMessage();
const modal = useModal();
const showModal = ref(false);

const appMessage = computed(() => store.getters.appMessage);
const messageType = ref(null);
const notificationMessage = ref(null);

watch(appMessage, (newVal, oldVal) => {
    var imessageType = store.getters.appMessageType;

    if (appMessage) {
        messageType.value = imessageType;
        notificationMessage.value = newVal.split(" | ")[1];
        showModal.value = true;

        // setTimeout(() => {
        //     showModal.value = false;
        // }, 3500);
    }
});

onMounted(() => {
    if (localStorage.getItem("languagePopupDone") !== "true") {
        modal.create({
            preset: "card",
            style: {
                width: "400px",
            },
            content: () => h(LandingModal, {}),
            onAfterLeave: () => {
                // store.dispatch("setLanguagePopupDisplayed", true);
                localStorage.setItem("languagePopupDone", "true")
            }
        })
    }
})
</script>

<style lang="css" scoped>
.ais {
    font-size: 4.5rem;
    color: red;
}
.footer {
    color: white;
    background-color: black;
    /* background-color: var(--sky-blue-color); */
    width: 100%;
    /* height: 200px; */
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.footer .n-text,
.n-h,
.n-h5,
.n-a {
    color: white;
}
</style>