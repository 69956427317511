import { createApp } from 'vue'

import store from "./store";
import router from "./router";

// Import Naive UI
import naive from 'naive-ui';

import './style.css'
import App from './App.vue'


import 'air-datepicker/air-datepicker.css';

const app = createApp(App)

app.use(store)
app.use(router)
app.use(naive)

app.mount('#app')