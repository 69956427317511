<template>
    <n-spin :show="isLoading">
        <n-card :bordered="false" style="text-align: left">
            <h3 style="font-weight: 500; text-align: center">IMMIGRATION MEDICAL APPOINTMENT REQUEST</h3>
            <n-form ref="formRef" :model="formValue" :rules="rules">
                <n-grid cols="1" item-responsive>
                    <n-gi span="1">
                        <n-form-item label="First Name" path="firstName">
                            <n-input placeholder="First Name" v-model:value="formValue.firstName"></n-input>
                        </n-form-item>
                        <n-form-item label="Middle Name" path="middleName">
                            <n-input placeholder="Middle Name" v-model:value="formValue.middleName"></n-input>
                        </n-form-item>
                        <n-form-item label="Last Name" path="lastName">
                            <n-input placeholder="Last Name" v-model:value="formValue.lastName"></n-input>
                        </n-form-item>
                    </n-gi>
                    <n-gi span="1">
                        <n-form-item label="Email" path="email">
                            <n-input placeholder="Email" type="email" v-model:value="formValue.email"></n-input>
                        </n-form-item>
                    </n-gi>
                    <n-gi span="1">
                        <n-form-item label="Phone" path="phone">
                            <n-input type="number" placeholder="Phone" v-model:value="formValue.phone"></n-input>
                        </n-form-item>
                    </n-gi>
                    <n-gi span="1">
                        <n-form-item label="City/Location" path="location">
                            <n-input placeholder="City/Location" v-model:value="formValue.location"></n-input>
                        </n-form-item>
                    </n-gi>
                    <n-gi span="1">
                        <n-form-item label="Name of Medical Insurance" path="medicalInsurance">
                            <n-input placeholder="Name of Medical Insurance"
                                v-model:value="formValue.medicalInsurance"></n-input>
                        </n-form-item>
                    </n-gi>
                    <n-gi span="1">
                        <n-form-item label="Do you have Primary Care Doctor?">
                            <n-radio :checked="formValue.hasPrimaryCareDoctor" value="yes"
                                name="has-primary-care-doctor"
                                @update:checked="(v) => handlePrimaryCareChange(v, 'yes')">Yes</n-radio>
                            <n-radio :checked="!formValue.hasPrimaryCareDoctor" value="no"
                                name="has-primary-care-doctor"
                                @update:checked="(v) => handlePrimaryCareChange(v, 'no')">No</n-radio>
                        </n-form-item>
                    </n-gi>
                    <n-gi span="1">
                        <n-form-item label="Age of applicant/applicants">
                            <n-input type="textarea" placeholder="Age of applicant/applicants"
                                v-model:value="formValue.applicantDetails"></n-input>
                        </n-form-item>
                    </n-gi>
                    <n-gi span="1">
                        <n-form-item label="Do you have labs or vaccnine records from past?">
                            <n-input type="textarea" placeholder="Do you have labs or vaccnine records from past?"
                                v-model:value="formValue.vaccineRecords"></n-input>
                        </n-form-item>
                    </n-gi>
                    <n-gi span="1">
                        <n-upload multiple v-model:file-list="fileList">
                            <n-button text>
                                <n-icon :size="25">
                                    <Attach24Filled />
                                </n-icon>&nbsp;<b>Upload File(s)</b>
                            </n-button>
                        </n-upload>
                    </n-gi>
                    <n-gi span="1">
                        <n-space justify="center">
                            <n-button primary type="primary" @click="createAppointment">Send</n-button>
                        </n-space>
                    </n-gi>
                </n-grid>
            </n-form>
        </n-card>
    </n-spin>
</template>
<script setup lang="js">

import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Attach24Filled } from "@vicons/fluent";

import MiscService from "../services/misc.service";
import { languageVerboseMap } from "../utils";

const store = useStore();
const router = useRouter();

const isLoading = computed(() => store.getters.isLoading);
const currentLanguage = computed(() => store.getters.currentLanguage)

const formRef = ref();
const initFormValue = {
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    phone: "",
    location: "",
    medicalInsurance: "",
    hasPrimaryCareDoctor: false,
    scheduledDate: "",
    applicantDetails: "",
    vaccineRecords: ""
};
const formValue = ref(initFormValue);

const rules = {
    firstName: {
        required: true,
        trigger: "blur",
        message: "Please input your first name",
    },
    email: {
        required: true,
        trigger: "blur",
        message: "Please input a valid email id.",
        validator: (rule, value) => {
            if (!value) {
                return new Error("Email is required");
            }
            const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
            if (!emailRegex.test(value)) {
                return new Error("Please enter a valid email address");
            }
            return true; // Validation passed                
        }
    },
    phone: {
        required: true,
        trigger: "blur",
        message: "Please input a valid contact number",
        validator: (rule, value) => {
            if (!value) {
                return new Error("Please enter your contact number");
            }
            const contactRegex = /^[0-9]{10}$/;
            if (!contactRegex.test(value)) {
                return new Error("Please enter a valid contact number");
            }
            return true; // Validation passed
        }
    },
    location: {
        required: true,
        trigger: "blur",
        message: "Please input your location",
    },
    medicalInsurance: {
        required: true,
        trigger: "blur",
        message: "Please input your medical insurance",
    },
}

const fileList = ref([]);

const createAppointment = async () => {

    formRef.value.validate(async (errors) => {
        if (!errors) {
            formValue.value.files = fileList.value;
            store.dispatch("updateIsLoading", true);
            var data = formValue.value;
            data.preferredLanguage = languageVerboseMap[currentLanguage.value];
            await MiscService.createAppointment(data)
            store.dispatch("updateIsLoading", false);
            Object.assign(formValue.value, initFormValue);
            formValue.value.firstName = ""
            formValue.value.middleName = ""
            formValue.value.lastName = ""
            formValue.value.email = ""
            formValue.value.phone = ""
            formValue.value.location = ""
            formValue.value.medicalInsurance = ""
            formValue.value.hasPrimaryCareDoctor = false
            formValue.value.scheduledDate = ""
            formValue.value.applicantDetails = ""
            formValue.value.vaccineRecords = ""
            fileList.value = []

            store.dispatch("updateAppMessage", { msg: "Thank you for sharing your information. We will reach out to you shortly. Please check your phone message and email for further communication!", type: "success" });
        }
        else {
            console.log(errors)
        }
    })
}

const handlePrimaryCareChange = (value, option) => {
    formValue.value.hasPrimaryCareDoctor = option === 'yes' && value === true;
}

</script>