import { createRouter, createWebHistory } from "vue-router";

import store from "../store";

import Home from "@/views/HomeView.vue";
import Appointments from "../views/Appointments.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import TermsOfService from "../views/TermsOfService.vue";
import FAQ from "../views/FAQ.vue";
import I693 from "../views/I693.vue";
import PreCheckout from "../views/PreCheckout.vue";
import CheckoutConfirmation from "../views/CheckoutConfirmation.vue";
import I693FormView from "../views/I693FormView.vue";
import LoginView from "../views/LoginView.vue";
import AdminView from "../views/AdminView.vue";
import LogoutView from "../views/LogoutView.vue";


const routes = [
    {
      path: "/",
      name: "home",
      component: Home,
      meta: { title: "Ask For Cure" },
    },
    {
      path: "/appointments",
      name: "appointments",
      component: Appointments,
      meta: { title: "Ask For Cure | Appointment" },
    },
    {
      path: "/i-693",
      name: "i693",
      component: I693,
      meta: { title: "Ask For Cure | I693" },
    },
    {
      path: "/i-693-form",
      name: "i693-form",
      component: I693FormView,
      meta: { title: "Ask For Cure | I693 Form" },
    },
    {
      path: "/faqs",
      name: "faqs",
      component: FAQ,
      meta: { title: "Ask For Cure | FAQs" },
    },
    {
      path: "/pre-checkout",
      name: "pre-checkout",
      component: PreCheckout,
      meta: { title: "Ask For Cure | FAQs" },
    },
    {
      path: "/checkout/confirmation",
      name: "checkout-confirmation",
      component: CheckoutConfirmation,
      meta: { title: "Ask For Cure | Thankyou" },
    },
    {
      path: "/privacy-policy",
      name: "privacy-policy",
      component: PrivacyPolicy,
      meta: { title: "Ask For Cure | Privacy Policy" },
    },
    {
      path: "/terms-of-service",
      name: "terms-of-service",
      component: TermsOfService,
      meta: { title: "Ask For Cure | Terms Of Service" },
    },
    {
      path: "/login",
      name: "login",
      component: LoginView,
      meta: { title: "Ask For Cure | Admin Login" },
    },
    {
      path: "/logout",
      name: "logout",
      component: LogoutView,
      meta: { requiresAuth: true, title: "Ask For Cure | Admin Login" },
    },
    {
      path: "/admin",
      name: "admin",
      component: AdminView,
      meta: { requiresAuth: true, title: "Ask For Cure | Admin" },
    },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const isAuthenticated = await store.getters.isAuthenticated;

  if (to.meta.requiresAuth && !isAuthenticated) {
    next({ name: "login", query: { next: to.fullPath } });
    return;
  }
  next();
})

export default router;