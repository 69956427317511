<template>
    <div>lala</div>
</template>

<script setup lang="js">
import { onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

const store = useStore();
const router = useRouter();


onMounted(() => {
    store.dispatch("updateLoggedIn", { token: null })
    router.push("/login");
})

</script>