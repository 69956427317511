// src/services/auth.service.ts
import http from './http.service';

const AuthService = {
    async login(data) {
        try {
            const response = await http.post('/auth/login/', JSON.stringify(data));

            if (response.status === 200) {
                return response.data;
            }
        } catch (error) {
            return {};
        }
    },
}

export default AuthService;