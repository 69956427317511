const languageVerboseMap = {
  "en": "English",
  "zh-cn": "Chinese",
  "pa": "Punjabi",
  "ar": "Arabi",
  "es": "Spanish",
  "ps": "Pashto",
  "pt": "Potugese",
  "fa": "Farsi",
}

const fileTypeMap = {
  "front": 0,
  "back": 1,
  "signature": 2,
  "application_file": 3,
}

const fileTypeVerboseMap = {
  0: "front",
  1: "back",
  2: "signature",
  3: "application_file",
}

const embeddedLanguageVideoMap = {
  "en": "https://www.youtube.com/embed/hsZRNZ_Qug4?rel=0",
  "zh-cn": "https://www.youtube.com/embed/iZUx71Zn19s?rel=0",
  "pa": "https://www.youtube.com/embed/rZvRtHmz2jo?rel=0",
  "ar": "https://www.youtube.com/embed/rzjEpeCNFiA?rel=0",
  "es": "https://www.youtube.com/embed/M49LZLuPUuc?rel=0",
  "ps": "https://www.youtube.com/embed/dS2sXGjiAAQ?rel=0",
  "pt": "https://www.youtube.com/embed/-XfhHFyNgcQ?rel=0",
  "fa": "https://www.youtube.com/embed/dP_P98sU8Lw?rel=0"
}

const languageVideoMap = {
  "en": "https://youtu.be/hsZRNZ_Qug4?si=p9iiidDtNLqlAHzO",
  "zh-cn": "https://youtu.be/iZUx71Zn19s?si=p9iiidDtNLqlAHzO",
  "pa": "https://youtu.be/rZvRtHmz2jo?si=p9iiidDtNLqlAHzO",
  "ar": "https://youtu.be/rzjEpeCNFiA?si=p9iiidDtNLqlAHzO",
  "es": "https://youtu.be/M49LZLuPUuc?si=p9iiidDtNLqlAHzO",
  "ps": "https://youtu.be/dS2sXGjiAAQ?si=p9iiidDtNLqlAHzO",
  "pt": "https://youtu.be/-XfhHFyNgcQ?si=p9iiidDtNLqlAHzO",
  "fa": "https://youtu.be/dP_P98sU8Lw?si=p9iiidDtNLqlAHzO"
}

const isMobile = () => {
  return window.innerWidth <= 768;
}

const getQueryParams = () => {
  const queryParamsString = location.search.substring(1);
  const queryParams = {};

  if (queryParamsString) {
    const queries = queryParamsString.split("&");
    for (const query of queries) {
      var [key, value] = query.split("=");
      value = value.split("+").join(" ");
      queryParams[key] = value;
    }
  }

  return queryParams;
}

const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop().split(';').shift();
  }
  return null; // Return null if the cookie is not found  
}

export {
  isMobile,
  getCookie,
  getQueryParams,
  embeddedLanguageVideoMap,
  languageVideoMap,
  languageVerboseMap,
  fileTypeMap,
  fileTypeVerboseMap
}