<template>
    <n-grid style="margin-top: 2rem; min-height: 400px;" cols="1" item-responsive>
        <n-gi>
            <n-space justify="center" class="banner-space">
                <n-h1 style="text-transform: uppercase">I-693 immigration medical exam, uscis doctors, green card
                    medical exam</n-h1>
            </n-space>
        </n-gi>
        <n-gi style="padding-left: 1rem; padding-right: 1rem;">
            <n-h2>All the required labs and vaccines are listed <u><n-a
                        href="/appointments#requirements-check-section">here</n-a></u>. </n-h2>
        </n-gi>
        <n-gi style="padding-left: 1rem; padding-right: 1rem;">
            <n-h3><n-a :href="languageVideoMap[currentLanguage] ?? languageVideoMap['en']">CLICK TO VIEW VIDEO TUTORIAL
                    FOR IMMIGRATION
                    EXAM</n-a></n-h3>
            <n-h3><n-a :href="requestAppointmentURL">CLICK HERE
                    TO START IMMIGRATION MEDICAL EXAM TODAY!</n-a></n-h3>
            <n-divider />
        </n-gi>
        <n-gi style="padding-top: 2rem; padding-left: 1rem; padding-right: 1rem; text-align: center; ">
            <n-h1>Request an Appointment</n-h1>
            <div style="width: 100%; display: flex; justify-content: center">
                <RequestAppointment style="max-width: 800px;" />
            </div>
        </n-gi>
    </n-grid>
</template>

<script setup lang="js">
import { computed } from 'vue';
import { useStore } from 'vuex';

import RequestAppointment from '../components/RequestAppointment.vue';
import { languageVideoMap } from '../utils';

const store = useStore();

const currentLanguage = computed(() => store.getters.currentLanguage);
const requestAppointmentURL = computed(() => `${import.meta.env.VITE_APP_URL}/appointments?lang=${currentLanguage.value}#request-an-appointment`)

</script>

<style lang="css" scoped>
.banner-space {
    padding-left: 30rem;
    padding-right: 30rem;
}

@media(max-width: 600px) {
    .banner-space {
        /* width: 100%; */
        padding-left: 2rem;
        padding-right: 2rem;
    }
}
</style>