<template>
    <div class="reviews-parent-div">
        <n-space vertical style="padding-top: 3rem;">
            <n-h1 class="block-case fancy-font review-section-heading">Testimonials</n-h1>
            <n-space justify="center" style="align-items: center" :size="[18, 5]" v-if="averageRating">
                <n-avatar round :size="40" style="background-color: white; margin-top:10px;">

                    <n-avatar round :src="GoogleLogo" :size="30"
                        style="margin-top:5px; background-color: transparent"></n-avatar>
                </n-avatar>
                <n-text class="average-rating notranslate" v-if="!isMobile()">{{ parseFloat(averageRating *
                    1.0).toFixed(1)
                    }}</n-text>
                <n-space vertical justify="center" :style="isMobile() ? 'align-items: center': 'align-items: start'"
                    :size=[3,3]>
                    <n-text style="font-size: large; color: white">I693 Civil Surgeon LLC, Dr Vinit Agrawal</n-text>
                    <n-rate readonly :value="parseInt(averageRating)" v-if="!isMobile()"></n-rate>
                    <n-space justify="center" style="align-items: center" v-if="isMobile()">
                        <n-text class="notranslate"
                            style="top: -0.19rem; font-size: 22px; color: white; position: relative; font-weight: 600">{{
                            parseFloat(averageRating * 1.0).toFixed(1) }}</n-text>
                        <n-rate readonly :value="parseInt(averageRating)"></n-rate>
                    </n-space>
                    <n-text style="color: white; text-decoration: underline; cursor: pointer"
                        @click="openBusinessMapLink">View All {{ totalRatings }} Reviews</n-text>
                </n-space>
            </n-space>
            <n-space justify="center" style="align-items: center" :size="[10, 0]">
                <n-text class="nav-text" style="font-size: 20px; cursor: pointer; color: white; font-weight: 500"
                    @click="changeReview(-1)">
                    < </n-text>
                        <n-card v-for="(review, index) in visibleReviews" :key="index + reviewStart"
                            class="review-card">
                            <n-space vertical justify="center" :gap="[0, 1]">
                                <n-image round :src="review.author_pic" :width="100"
                                    style="background: transparent;"></n-image>
                                <n-rate readonly :default-value="review.rating"
                                    :size="isMobile() ? 'small': 'medium'" />
                                <n-text class="review-text">{{ review.review.length > 150 ? review.review.substring(0,
                                    isMobile() ? (currentLanguage === 'es' ? 30 : 70) : 50) :
                                    review.review }}</n-text>
                                <n-text style="font-size: 12px;">
                                    {{review.author}}
                                    <br />
                                    <span
                                        style="font-size: 11px;">{{dayjs.unix(review.date).format('MM/DD/YYYY')}}</span>
                                </n-text>
                                <n-button text :class="'google-review-button ' + currentLanguage" type="info"
                                    @click="openBusinessMapLink">
                                    <template #icon>
                                        <n-avatar round :src="GoogleLogo" style="background-color: transparent"
                                            :size="20"></n-avatar>
                                    </template>
                                    Read Full Review >
                                </n-button>
                            </n-space>
                        </n-card>
                        <n-text class="nav-text"
                            style="font-size: 20px; cursor: pointer; color: white; font-weight: 500"
                            @click="changeReview(1)">
                            > </n-text>
            </n-space>
        </n-space>
    </div>

</template>

<script setup lang="js">
import { computed, ref, onMounted } from "vue";
import { useStore } from "vuex";
import dayjs from 'dayjs';

import MiscService from "../services/misc.service";
import GoogleLogo from "@/assets/images/google-logo.png";
import { isMobile } from "@/utils";

const store = useStore();

const reviews = ref([]);
const averageRating = ref();
const totalRatings = ref();
const reviewStart = ref(0); // The starting index of the visible reviews
const numVisible = window.innerWidth <= 600 ? 1 : 3; // Number of visible reviews
const currentLanguage = computed(() => store.getters.currentLanguage);

const getReviews = async () => {
    const response = await MiscService.getBusinessReviews();
    reviews.value = response["reviews"];
    averageRating.value = response["average_rating"];
    totalRatings.value = response["total_ratings"];
}

const visibleReviews = computed(() => {
    return reviews.value.slice(reviewStart.value, reviewStart.value + numVisible);
});

const changeReview = (direction) => {
    const totalReviews = reviews.value.length;

    // Adjust reviewStart by direction and wrap around using modulo
    reviewStart.value = (reviewStart.value + direction * numVisible + totalReviews) % totalReviews;
}

const init = async () => {
    getReviews();
}

onMounted(() => {
    init();
})

const openBusinessMapLink = () => {
    window.open("https://maps.google.com/maps?cid=11454851845556332342", "_blank");
}
</script>

<style lang="css">
.n-button__content .n-text.nav-text {
    font-size: 30px;
    color: white;
    font-weight: 900;
}

.reviews-parent-div {
    margin-top: 3rem;
    height: 600px;
    background-color: #ABABAB;
    /* background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.24) 0%, rgba(0, 0, 0, 0.24) 100%), url('//img1.wsimg.com/isteam/ip/ec55009f-efc5-4b22-b395-d29a537c3ba5/blob.png/:/cr=t:0.46%25,l:0%25,w:99.54%25,h:99.54%25/rs=w:1920,m') */
}

.review-card {
    width: 300px;
    height: 330px;
}

.review-section-heading {
    font-size: 48px;
    color: white;
    margin-bottom: 0px;
    }
    
    .n-text.review-text {
        font-size: 16px;
        line-height: 0.1rem;
    }
    
    .google-review-button {
        margin-top: 0.5rem
    }
    
                .google-review-button.es {
                    font-size: 12px;
                }
    .average-rating {
        font-size: 50px;
        font-weight: 700;
        color: white
    }
    
    @media(max-width: 600px) {
        .reviews-parent-div {
            /* background-color: red; */
            height: 650px;
            background-color: #ABABAB;
            /* background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.24) 0%, rgba(0, 0, 0, 0.24) 100%), url('//img1.wsimg.com/isteam/ip/ec55009f-efc5-4b22-b395-d29a537c3ba5/blob.png/:/cr=t:0.46%25,l:0%25,w:99.54%25,h:99.54%25/rs=w:1920,m') */
        }
    
        .review-card {
            width: 230px;
            height: 330px;
            overflow: hidden;
        }
    
        .review-section-heading {
            font-size: 30px;
            color: white;
            margin-bottom: 0px;
        }
    
        .n-button__content .n-text.nav-text {
            font-size: 20px;
            color: white;
            font-weight: 900;
        }
    
        .n-text.review-text {
            font-size: 14px;
            line-height: 0.1rem;
        }
    
        .google-review-button {
            margin-top: 0rem
        }
    
        .average-rating {
            font-size: 30px;
            font-weight: 700;
            color: white
        }
}
</style>