<template>
    <n-h2 style="margin-top: 3rem;">
        <n-space vertical justify="center">
            <n-text>
                Book Lab Tests/Immunity Tests
            </n-text>
        </n-space>
    </n-h2>
    <n-space vertical justify="center" style="margin-top: 2rem; align-items: center; min-height: 60vh;">
        <n-card class="main-card" title="" style="margin-bottom: 2rem;">
            <div style="margin-top: 1rem; margin-bottom: 1rem; text-align: left;">
                <n-text depth="3">
                    Once you purchase the labs, Your requested lab order will be sent to any LabCorp location near you
                    and you will get an email confirmation, typically within few hours. They will take the sample and
                    send us the results. We will contact you once we get the results.
                    <br />
                    <br />
                    You can WALK-IN at most Labcorp locations or use the following link to find close by LabCorp
                    location and make an appointment:
                    <a href="https://www.labcorp.com/labs-and-appointments">LabCorp Locations</a>
                </n-text>
            </div>
            <n-space justify="left">
                <n-form-item label="Please choose your age group to see the list of tests:" class="age-group-dropdown"
                    :label-placement="isMobile() ? 'top': 'left'">
                    <n-select v-model:value="selectedAgeGroup" :options="ageGroupOptions"
                        @update:value="ageGroupSelected" style="max-width: 200px;" />
                </n-form-item>
            </n-space>
            <n-divider></n-divider>
            <n-grid cols="2" item-responsive v-if="selectedAgeGroup">
                <n-gi span="2 600:1">
                    <n-alert :show-icon="false" class="test-alert-heading">Select
                        Lab
                        Test(s)</n-alert>
                    <n-list :bordered="true" class="tests-list">
                        <n-list-item
                            v-for="test in labProducts.filter((item) => item.type === 'lab' && item.age_group === selectedAgeGroup)"
                            class="cursor-pointer" @click="selectTest(test.id)"
                            :class="selectedTests.indexOf(test.id) !== -1 ? 'success-bg': 'white-bg'" :key="test.id">
                            <n-button text class="lab-test-btn" icon-placement="right"
                                :class="selectedTests.indexOf(test.id) !== -1 ? 'white-text': 'black-text'">
                                <n-space vertical>
                                    <n-text>{{ test.name }}</n-text>
                                    <n-text class="mini-text">${{ test.price }}</n-text>
                                </n-space>
                                <template #icon>
                                    <n-icon :color="selectedTests.indexOf(test.id) === -1 ? '#C0C0C0': 'black'"
                                        size="20">
                                        <CheckmarkCircle24Filled v-if="selectedTests.indexOf(test.id) !== -1" />
                                        <CheckmarkCircle24Regular v-if="selectedTests.indexOf(test.id) === -1" />
                                    </n-icon>
                                </template>
                            </n-button>
                        </n-list-item>
                    </n-list>
                    <n-space>&nbsp;</n-space>
                    <n-alert class="test-alert-heading">Select Immunity Test(s)</n-alert>
                    <n-list :bordered="true" class="tests-list">
                        <n-list-item v-for="test in labProducts.filter((item) => item.type === 'immunity')"
                            class="cursor-pointer" @click="selectTest(test.id)"
                            :class="selectedTests.indexOf(test.id) !== -1 ? 'success-bg': 'white-bg'">
                            <n-button text class="lab-test-btn" icon-placement="right"
                                :class="selectedTests.indexOf(test.id) !== -1 ? 'white-text': 'black-text'">
                                <n-space vertical>
                                    <n-text>{{ test.name }}</n-text>
                                    <n-text class="mini-text">${{ test.price }}</n-text>
                                </n-space>
                                <template #icon>
                                    <n-icon :color="selectedTests.indexOf(test.id) === -1 ? '#C0C0C0': 'black'"
                                        size="20">
                                        <CheckmarkCircle24Filled v-if="selectedTests.indexOf(test.id) !== -1" />
                                        <CheckmarkCircle24Regular v-if="selectedTests.indexOf(test.id) === -1" />
                                    </n-icon>
                                </template>
                            </n-button>
                        </n-list-item>
                    </n-list>
                </n-gi>
                <n-gi span="2 600:1">
                    <n-card :bordered="false" style="height: 100%;" id="cost-display-div">
                        <n-empty v-if="selectedTests.length === 0" description="Select test(s) to continue"></n-empty>
                        <n-space vertical v-if="selectedTests.length > 0" justify="center" style="align-items: center">
                            <n-text style="font-size: 45px; font-weight: 800">${{ totalCost }}</n-text>
                            <n-button type="primary" @click="toggleCustomerInfoDiv(true)">Continue</n-button>
                        </n-space>
                    </n-card>
                </n-gi>
            </n-grid>
        </n-card>
    </n-space>

    <n-space id="mandatory-form-input" vertical style="padding-left: 2rem; padding-right: 2rem; align-items: center"
        :class="isMobile() ? 'text-left': 'text-center'" v-show="showCustomerInfoDiv">
        <n-text depth="3"><sup>*</sup>It is crucial to provide the following details for Lab/Immunity tests to
            proceed:</n-text>
        <n-space style="margin-top: 1rem;" justify="center">
            <n-form ref="formRef" :model="formValue" :rules="rules" :validate-messages="validateMessages"
                style="text-align: left">
                <n-form-item label="First Name(of the applicant)" path="user.firstName">
                    <n-input id="first-name-input" v-model:value="formValue.user.firstName"></n-input>
                </n-form-item>
                <n-form-item label="Middle Name(of the applicant)" path="user.middleName">
                    <n-input placeholder="(optional)" v-model:value="formValue.user.middleName"></n-input>
                </n-form-item>
                <n-form-item label="Last Name(of the applicant)" path="user.lastName">
                    <n-input v-model:value="formValue.user.lastName"></n-input>
                </n-form-item>
                <n-form-item label="Gender(of the applicant)" path="user.gender">
                    <n-select v-model:value="formValue.user.gender" :options="genderOptions"></n-select>
                </n-form-item>
                <n-form-item label="Date Of Birth(of the applicant)" class="dob-picker checkout"
                    path="user.dateOfBirth">
                    <input id="pdob" readonly
                        style="width: 100%; height: 30px; border: 1px solid #E0E0E6; padding-left: 12px; padding-right: 12px; text-align: left; border-radius: 3px;"></input>
                </n-form-item>
                <n-form-item label="Contact Number" path="user.contactNumber">
                    <n-input id="contact-number" placeholder="Contact no" v-model:value="formValue.user.contactNumber"
                        style="text-align: center;" type="number">
                        <template #prefix>
                            +1
                        </template>
                    </n-input>
                </n-form-item>
            </n-form>
        </n-space>
        <n-button v-if="!paymentURL" size="large" type="success" @click="openStripeCheckout" :loading="isLoading"
            style="margin-bottom: 2rem;">Continue</n-button>
    </n-space>
    <n-space vertical style="padding-left: 2rem; padding-right: 2rem; align-items: center">
        <a v-if="paymentURL" :href="paymentURL" @click="clearPaymentURL"
            style="font-size: 20px; font-weight: 600; background-color: black; color: white; padding: 0.5rem;">Pay
            Now</a>
        &nbsp;
    </n-space>
</template>

<script setup lang="js">
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";

import dayjs from 'dayjs';
import posthog from "posthog-js";
import AirDatepicker from 'air-datepicker';
import localeEn from 'air-datepicker/locale/en';
import { CheckmarkCircle24Filled, CheckmarkCircle24Regular } from "@vicons/fluent";

import { getQueryParams, isMobile } from "@/utils";
import MiscService from "../services/misc.service";

const store = useStore();

const isLoading = ref(false);
const labProducts = ref([]);
const productsMap = ref({});
const selectedTests = ref([]);
const totalCost = ref(0);
const showCustomerInfoDiv = ref(false);
const currentLanguage = ref("en");
const selectedAgeGroup = ref();
const gender = ref(null);
const formError = ref(true);
const paymentURL = ref();

const formRef = ref(null);
const formValue = ref({
    user: {
        firstName: "",
        middleName: "",
        lastName: "",
        dateOfBirth: "",
        gender: "",
        contactNumber: ""
    }
})

const validateMessages = ref({
    messages: {
        required: 'This field is required!',
    }
})

const rules = ref({
    user: {
        firstName: {
            required: true,
            trigger: "blur",
            validator: (rule, value) => {
                if (!value) {
                    return new Error("Please enter the first name");
                }
            }
        },
        middleName: {
            required: false,
            trigger: "blur"
        },
        lastName: {
            required: true,
            trigger: "blur",
            validator: (rule, value) => {
                if (!value) {
                    return new Error("Please enter the last name");
                }
            }
        },
        gender: {
            required: true,
            trigger: "blur",
            validator: (rule, value) => {
                if (!value) {
                    return new Error("Please select the gender");
                }
            }
        },
        dateOfBirth: {
            required: true,
            validator: (rule, value) => {
                if (!value) {
                    return new Error("Please enter the birth date");
                }
            }
        },
        contactNumber: {
            required: true,
            validator: (rule, value) => {
                if (!value) {
                    return new Error("Please enter the contact number");
                }
                const contactRegex = /^[0-9]{10}$/;
                if (!contactRegex.test(value)) {
                    return new Error("Please enter a valid contact number");
                }
                return true; // Validation passed
            }
        },
    }
})

const cinit = async () => {
    const response = await MiscService.getLabProducts();
    if (response.data) {
        labProducts.value = response.data;
        for (const product of labProducts.value) {
            productsMap.value[product.id] = product
        }
    }
}

onMounted(() => {
    const params = getQueryParams();
    cinit();
})

const stripeLanguageMap = {
    "en": "en",
    "es": "es",
    "ps": "en",
    "zh-CN": "zh",
}

const ageGroupOptions = [
    {
        label: "18-24 years",
        value: "18-24",
    },
    {
        label: "25-44 years",
        value: "25-44",
    },
    {
        label: "others",
        value: "others",
    }
]

const genderOptions = [
    {
        label: "Male",
        value: "male",
    },
    {
        label: "Female",
        value: "female",
    },
    {
        label: "Other",
        value: "other",
    }
]

const selectTest = async (testId) => {
    // setTimeout(() => {
    //     document.getElementById("cost-display-div").scrollIntoView({ behavior: "smooth", block: "start" });
    // }, 200);

    currentLanguage.value = await store.getters.currentLanguage;
    let testIdIndex = selectedTests.value.indexOf(testId);

    const exclusionMap = {
        "prod_RLiSzfSHN9WWRj": ["prod_R8eFh7Axc2BmOU", "prod_R8eFu530dnAR9f", "prod_R8eC4on9MoO90L"],
        "prod_RLF6TKZfGnEYEt": [],
        "prod_RGLJaPFUrAjRYd": ["prod_RBCB36V06h5GZF"],
        "prod_RBCCLaSqYd6Qnf": ["prod_RBCCp7Ew4c8OMB", "prod_RBCB36V06h5GZF"],
        "prod_RBCCrBYiT4puBH": ["prod_RBCCp7Ew4c8OMB", "prod_RBCB36V06h5GZF"],
        "prod_RBCCp7Ew4c8OMB": ["prod_RBCCLaSqYd6Qnf", "prod_RBCCrBYiT4puBH", "prod_RBCB36V06h5GZF"],
        "prod_RBCB36V06h5GZF": ["prod_RBCCLaSqYd6Qnf", "prod_RBCCrBYiT4puBH", "prod_RGLJaPFUrAjRYd", "prod_RBCCp7Ew4c8OMB"],
        "prod_R8eFh7Axc2BmOU": ["prod_RLiSzfSHN9WWRj", "prod_R8eFu530dnAR9f", "prod_R8eC4on9MoO90L"],
        "prod_R8eF16sJrYNihj": ["prod_R8eFu530dnAR9f", "prod_R8eC4on9MoO90L"],
        "prod_R8eFu530dnAR9f": ["prod_R8eF16sJrYNihj", "prod_R8eFh7Axc2BmOU", "prod_RLiSzfSHN9WWRj", "prod_R8eC4on9MoO90L"],
        "prod_R8eC4on9MoO90L": ["prod_R8eF16sJrYNihj", "prod_R8eFh7Axc2BmOU", "prod_R8eFu530dnAR9f", "prod_RLiSzfSHN9WWRj"]
    };

    if (testIdIndex !== -1) {
        selectedTests.value.splice(testIdIndex, 1);
        totalCost.value = totalCost.value - productsMap.value[testId].price
    } else {
        selectedTests.value.push(testId);
        totalCost.value = totalCost.value + productsMap.value[testId].price

        const exclusions = exclusionMap[testId] || [];
        exclusions.forEach(excludedTestId => {
            const excludedIndex = selectedTests.value.indexOf(excludedTestId);
            if (excludedIndex !== -1) {
                selectedTests.value.splice(excludedIndex, 1);
                totalCost.value -= productsMap.value[excludedTestId].price;
            }
        });
    }

    // setTimeout(() => {
    //     initiateResizeDobPicker()
    // }, 10);
    // bindDobPicker();
}

const toggleCustomerInfoDiv = (value) => {
    showCustomerInfoDiv.value = value;
    setTimeout(() => {
        document.getElementById("mandatory-form-input").scrollIntoView({
            behavior: "smooth",
            block: "start"
        })
        bindDobPicker();
        initiateResizeDobPicker();
    }, 200);
}

const openStripeCheckout = async () => {
    posthog.capture('payment_intent');
    formRef.value?.validate(async (error) => {
        if (error) {

        } else {
            if (formValue.value.user.dateOfBirth === null) {
                store.dispatch("updateAppMessage", { msg: "Please input your birthdate.", type: "error" })
            } else {
                isLoading.value = true;
                const response = await MiscService.initiatePayment({
                    first_name: formValue.value.user.firstName,
                    middle_name: formValue.value.user.middleName,
                    last_name: formValue.value.user.lastName,
                    gender: formValue.value.user.gender,
                    dob: dayjs(formValue.value.user.dateOfBirth.date).format("YYYY-MM-DD"),
                    test_name: "",
                    tests: selectedTests.value,
                    amount: totalCost.value,
                    phone: formValue.value.user.contactNumber
                })
                isLoading.value = false;
                // debugger;
                if (response.data.transaction_id) {
                    let stripeCheckoutLink = "";
                    stripeCheckoutLink = response.data.payment_link;

                    // window.open(`${stripeCheckoutLink}?locale=${stripeLanguageMap[localStorage.getItem("currentLanguage")]}&client_reference_id=${response.data.transaction_id}`, "_blank")
                    paymentURL.value = `${stripeCheckoutLink}?locale=${stripeLanguageMap[localStorage.getItem("currentLanguage")]}&client_reference_id=${response.data.transaction_id}`
                    posthog.capture('payment_link_generated');
                    // formValue.value.user.firstName = "";
                    // formValue.value.user.middleName = "";
                    // formValue.value.user.lastName = "";
                    // formValue.value.user.gender = "";
                    // formValue.value.user.dateOfBirth = "";
                    // formValue.value.user.contactNumber = "";
                    // selectedAgeGroup.value = null;
                    // showCustomerInfoDiv.value = false;
                }
            }
        }
    })
}

const clearPaymentURL = () => {
    formValue.value.user.firstName = "";
    formValue.value.user.middleName = "";
    formValue.value.user.lastName = "";
    formValue.value.user.gender = "";
    formValue.value.user.dateOfBirth = "";
    formValue.value.user.contactNumber = "";
    selectedAgeGroup.value = null;
    showCustomerInfoDiv.value = false;
    paymentURL.value = "";
}

const ageGroupSelected = (value) => {
    totalCost.value = 0;
    selectedTests.value = [];
}

const bindDobPicker = () => {
    // debugger;
    new AirDatepicker('#pdob', {
        classes: "checkout",
        inline: false,
        locale: {
            days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            daysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            daysMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
            months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            today: 'Today',
            clear: 'Clear',
            dateFormat: 'MM/dd/yyyy',
            timeFormat: 'hh:ii aa',
            firstDay: 0
        },
        onSelect: (a) => {
            formValue.value.user.dateOfBirth = a;
        },
        onShow: () => {
            // document.querySelector(".air-datepicker").style.width = document.querySelector("#dob").style.width;
        }
    });
}

const initiateResizeDobPicker = () => {
    // Resizing the dateInput starts
    const referenceInput = document.getElementById("first-name-input")
    const datePickerTrigger = document.querySelector("#pdob")
    const datePickerContainer = document.querySelector(".dob-picker")

    datePickerContainer.style.width = `${referenceInput.offsetWidth}px`;
    // Resizing the dateInput ends    
    datePickerTrigger.addEventListener("focus", () => {
        setTimeout(() => {
            // Get the width of input box B
            const referenceWidth = referenceInput.offsetWidth;

            // Select the dynamically added calendar popup
            const datePicker = document.querySelector(".checkout.air-datepicker.-bottom-left-.-from-bottom-.-active-");
            // Check if the date picker exists
            if (datePicker) {
                // Set the width of the date picker to match the input box
                datePicker.style.width = `${referenceWidth}px`;
            }
        }, 10);
    });
    datePickerTrigger.addEventListener("click", () => {
        setTimeout(() => {
            // Get the width of input box B
            const referenceWidth = referenceInput.offsetWidth;

            // Select the dynamically added calendar popup
            const datePicker = document.querySelector(".checkout.air-datepicker.-bottom-left-.-from-bottom-.-active-");
            // Check if the date picker exists
            if (datePicker) {
                // Set the width of the date picker to match the input box
                datePicker.style.width = `${referenceWidth}px`;
            }
        }, 10);
    });
}
</script>

<style lang="css">
.main-card {
    width: 900px;
    /* margin-left: 10rem;
    margin-right: 10rem; */
}

.n-button.lab-test-btn.white-text .n-button__content {
    color: black;
}

.n-button.lab-test-btn.black-text .n-button__content {
    color: black;
}

.n-list-item.success-bg {
    /* background-color: var(--success-color); */
    background-color: #EBE7E4;
}

.n-list.tests-list .n-list-item:hover {
    background-color: #EBE7E4;
}

.age-group-dropdown {
    min-width: 500px;
}

.dob-picker {
    text-align: left;
    /* width: 307px; */
}

.dob-picker input {
    text-align: left;
    /* width: 307px; */
}

#pdob {
    background-color: transparent;
    color: black;
}

.test-alert-heading {
    font-size: 15px;
    color: var(--info-color);
    text-align: center;
    font-weight: 700;
}

@media(max-width: 600px) {
    .main-card {
        width: 100%;
    }

    .age-group-dropdown {
        width: 100%;
    }

    .n-form-item-label {
        text-align: left !important;
        max-width: 250px;
    }
}
</style>