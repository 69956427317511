<template>
    <div style="min-height: 350px; padding-bottom: 3rem;">
        <div class="success-div" v-if="isPaymentSuccess">
            <n-h2 style="margin-top: 3rem;">Thank You</n-h2>
            <n-space vertical justify="center">
                <n-icon color="#3b9a00" size="80">
                    <CheckmarkCircle24Regular />
                </n-icon>
                <n-p style="padding-left: 2rem; padding-right: 2rem; text-align: center">

                    <n-text depth="3" style="font-size: 13px;">
                        Thank you for ordering your labs. <b>You will shortly get an email with instructions to the
                            email
                            you
                            used
                            to pay for lab work</b>.
                    </n-text>
                    <br />
                    <n-text depth="3" style="font-size: 13px;">
                        Please wait upto 24 hours before contacting us and check your spam folder as well.
                    </n-text>
                    <!-- <n-text depth="2" style="font-size: 13px;">
                        <a :href="languageVideoMap[currentLanguage]">
                            Video Instruction
                            <n-icon style="position: relative; top: 0.15rem;">
                                <Open24Filled />
                            </n-icon></a>
                    </n-text> -->
                </n-p>
            </n-space>
        </div>
        <div class="success-div" v-if="!isPaymentSuccess">
            <n-h2 style="margin-top: 3rem;">Something went wrong!</n-h2>
            <n-space vertical justify="center">
                <n-icon color="#9a0033" size="80">
                    <Warning24Regular />
                </n-icon>
                <n-p style="padding-left: 2rem; padding-right: 2rem;">

                    <n-text style="font-size: 13px;">
                        If there were any issues with the payment process, please rest assured that:
                    </n-text>
                    <n-ul style="font-size: 13px;">

                        <n-li>
                            Any payment made will be reviewed promptly.
                        </n-li>
                        <n-li>
                            We will ensure appropriate adjustments or issue a full refund if necessary.
                        </n-li>
                    </n-ul>
                    <n-text style="font-size: 13px;">Your trust is our priority, and we are committed to resolving any
                        concerns quickly. Thank
                        you for choosing us for your lab test needs!</n-text>
                    <br />
                    <n-text style="font-size: 13px;"><a href="mailto:support@askforcure.com">Drop us an
                            email!</a></n-text>
                </n-p>
            </n-space>
        </div>
    </div>
</template>

<script setup lang="js">
import { computed, ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import posthog from 'posthog-js';
import { CheckmarkCircle24Regular, Open24Filled, Warning24Regular } from "@vicons/fluent";

import { getQueryParams, isMobile, languageVideoMap } from "@/utils";

const store = useStore();
const router = useRouter();

const isPaymentSuccess = ref(false);
const currentLanguage = computed(() => store.getters.currentLanguage)

onMounted(() => {
    cinit()
})

const cinit = () => {
    const queryParams = getQueryParams();
    if (queryParams.s === "t") {
        isPaymentSuccess.value = true;
        posthog.capture('payment_complete');
    }
}
</script>

<style lang="css" scoped></style>