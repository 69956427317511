<template>
    <n-space style="min-height: 600px; margin-top: 2rem;" justify="center">
        <n-grid cols="6" item-responsive>
            <n-gi span="6">
                <n-card class="admin-card" :bordered="false" style="min-width: 1200px;">
                    <div>
                        <n-list hoverable bordered>
                            <n-list-item style="background-color: var(--wheat-background-color);">
                                <n-grid cols="11" style="text-align: left" x-gap="5">
                                    <n-gi span="2">
                                        Customer
                                    </n-gi>
                                    <n-gi span="2">
                                        Files
                                    </n-gi>
                                    <n-gi span="2">
                                        Submitted on
                                    </n-gi>
                                    <n-gi span="3">
                                        Template
                                    </n-gi>
                                    <n-gi span="2">
                                        Action
                                    </n-gi>
                                </n-grid>
                            </n-list-item>
                        </n-list>
                    </div>
                    <div style="max-height: 50vh; overflow-y: scroll">


                        <n-list hoverable bordered>
                            <n-list-item v-for="application in applications">
                                <n-grid cols="11" style="text-align: left" x-gap="5">
                                    <n-gi span="2">
                                        {{ application.last_name }}, {{ application.first_name }}
                                    </n-gi>
                                    <n-gi span="2">
                                        <n-space vertical style="text-align: left">
                                            <n-a style="text-decoration: underline"
                                                @click="fetchFile(application.uuid, 'front')">ID Front</n-a>
                                            <n-a style="text-decoration: underline"
                                                @click="fetchFile(application.uuid, 'back')">ID Back</n-a>
                                            <!-- <n-a style="text-decoration: underline"
                                            @click="fetchFile(application.uuid, 'application_file')">Application
                                            File</n-a> -->
                                        </n-space>
                                    </n-gi>
                                    <n-gi span="2">
                                        {{ dayjs(application.created_on).format('lll') }}
                                    </n-gi>
                                    <n-gi span="3">
                                        <n-select :options="templateOptions"
                                            @update:value="(value) => chooseTemplate(value, application.uuid)"
                                            placeholder="Choose an office">

                                        </n-select>
                                    </n-gi>
                                    <n-gi span="2">
                                        <n-space style="width: 100%">
                                            <n-button type="primary" style="width: 100%"
                                                :disabled="currentApplication !== application.uuid || !templateChosen"
                                                @click="fetchPDF">Start Editing</n-button>
                                        </n-space>
                                    </n-gi>
                                </n-grid>
                            </n-list-item>
                        </n-list>
                    </div>
                    <n-divider></n-divider>
                </n-card>
            </n-gi>
        </n-grid>
    </n-space>
    <n-drawer v-model:show="showPhotoIDArea" :width="800" placement="right">
        <n-drawer-content title="Stoner">
            Stoner is a 1965 novel by the American writer John Williams.
            <!-- <VuePdfEmbed annotation-layer text-layer :source="pdfSource" v-if="pdfSource" /> -->
            <n-image :src="currentImage" v-if="currentImage"></n-image>
        </n-drawer-content>
    </n-drawer>
</template>
<script setup lang="js">
import { onMounted, ref } from "vue";
import { useStore } from "vuex";

import VuePdfEmbed from 'vue-pdf-embed'

// optional styles
import 'vue-pdf-embed/dist/styles/annotationLayer.css'
import 'vue-pdf-embed/dist/styles/textLayer.css'

import { CheckmarkCircle24Filled } from "@vicons/fluent";
import { ArrowDropDownCircleSharp } from "@vicons/material";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

import ApplicationService from "@/services/application.service.js";
import { fileTypeMap, fileTypeVerboseMap } from "@/utils";

const store = useStore();

const showPhotoIDArea = ref(false);
const currentImage = ref();
const pdfSource = ref();
const templateChosen = ref();
const currentApplication = ref();

const templateOptions = [
    { label: "Dublin", value: "dublin" },
    { label: "Fremont", value: "fremont" },
    { label: "Hayward", value: "hayward" },
    { label: "Modesto", value: "modesto" },
    { label: "Newark", value: "newark" },
    { label: "Redwood City", value: "redwood" },
    { label: "Santa Clara", value: "santa-clara" },
    { label: "Stockton", value: "stockton" },
    { label: "Tracy", value: "tracy" },
]

const actionOptions = [
    {
        label: 'Finish Application',
        value: 'finish'
    },
    {
        label: 'Send For Print',
        value: 'send_for_print'
    },
    {
        label: 'Mark Verified',
        value: 'mark_verified'
    },
]

const applications = ref([]);

const cinit = async () => {
    const response = await ApplicationService.getApplications();
    if (response && response.length > 0) {
        applications.value = response
    }
}

onMounted(() => {
    dayjs.extend(localizedFormat);
    cinit()
})

const chooseTemplate = (value, applicationUUID) => {
    currentApplication.value = applicationUUID;
    templateChosen.value = value
}

const fetchFile = async (uuid, fileType) => {
    showPhotoIDArea.value = !showPhotoIDArea.value;
    if (fileTypeMap[fileType] !== 3) {
        const response = await ApplicationService.fetchFile(uuid, fileTypeMap[fileType]);
        if (response.url && fileTypeMap[fileType] !== 3) {
            currentImage.value = response.url;
        }
    }

    // else if (response.url && fileTypeMap[fileType] === 3) {
    //     pdfSource.value = response.url;

    //     // Create a temporary anchor element
    //     const anchor = document.createElement('a');
    //     anchor.href = pdfSource.value;

    //     // Set the download attribute to specify the file name
    //     anchor.download = `${uuid}-ApplicationFile.pdf` || 'download';

    //     // Append the anchor to the document body
    //     document.body.appendChild(anchor);

    //     // Programmatically trigger a click event
    //     anchor.click();

    //     // Remove the anchor from the document
    //     document.body.removeChild(anchor);
    // }
}

const fetchPDF = async () => {
    store.dispatch("updateIsLoading", true);
    const response = await ApplicationService.fetchApplication(currentApplication.value, templateChosen.value)
    store.dispatch("updateIsLoading", false);
    console.log(response.url)

    if (response.url) {
        pdfSource.value = response.url;

        // Create a temporary anchor element
        const anchor = document.createElement('a');
        anchor.href = pdfSource.value;

        // Set the download attribute to specify the file name
        anchor.download = `${currentApplication.value}-ApplicationFile.pdf` || 'download';

        // Append the anchor to the document body
        document.body.appendChild(anchor);

        // Programmatically trigger a click event
        anchor.click();

        // Remove the anchor from the document
        document.body.removeChild(anchor);        
    }
}
</script>

<style scoped>
.admin-card .n-button {
    width: 75px;
    text-align: center;
}
</style>

