import http from './http.service';

const ApplicationService = {
    async getApplications() {
        try {
            const response = await http.get('/i693-application/');
            if (response.status === 200) {
                return response.data;
            }
        } catch (error) {
            return [];
        }
    
    },
    async fetchFile(uuid, fileType) {
        try {
            const response = await http.get(`/i693-application/${uuid}/file/?file-type=${fileType}`);
            if (response.status === 200) {
                return response.data;
            }
        } catch (error) {
            return [];
        }
    },
    async fetchApplication(uuid, templateId) {
        try {
            const response = await http.get(`/i693-application/${uuid}/file/i693/pdf/?office=${templateId}`);
            if (response.status === 200) {
                return response.data;
            }
        } catch (error) {
            return {};
        }
    },
}

export default ApplicationService;