<template>
    <div id="container">
        <div id="map"></div>
        <!-- <div class="overlay" @click="" v-show="showOverlay">CMD/CTRL + Click to interact with the map</div> -->
    </div>
</template>

<script setup lang="js">
import { onBeforeUnmount, onMounted, ref } from "vue";
import L from 'leaflet'; // Import Leaflet
import 'leaflet/dist/leaflet.css'; // Import Leaflet CSS

import MarkerIcon from '@/assets/images/marker.png';
import { isMobile } from "@/utils";

const map = ref();
const showOverlay = ref(true);

const serviceLocations = ref([
    { name: "San Mateo", coords: [37.5630, -122.3255] },
    { name: "Redwood City", coords: [37.4848, -122.2281] },
    { name: "Santa Clara", coords: [37.3541, -121.9552] },
    { name: "San Jose", coords: [37.3382, -121.8863] },
    { name: "Fremont", coords: [37.5483, -121.9886] },
    { name: "Newark", coords: [37.5297, -122.0402] },
    { name: "Oakland", coords: [37.8044, -122.2711] },
    { name: "Hayward", coords: [37.6688, -122.0808] },
    { name: "Dublin", coords: [37.7022, -121.9358] },
    { name: "Tracy", coords: [37.7397, -121.4252] },
    { name: "Modesto", coords: [37.6391, -120.9969] },
    { name: "Stockton", coords: [37.9577, -121.2908] }
])

onMounted(() => {
    var myIcon = L.icon({
        iconUrl: MarkerIcon,
        iconSize: [40, 40],
        iconAnchor: [20, 40],
    });

    map.value = L.map('map', {
        dragging: false,
        scrollWheelZoom: false
    }).setView(isMobile() ? [37.73144589186025, -121.62267729179436] : [37.7397, -121.4252], isMobile() ? 8 : 9);
    map.value.invalidateSize();
    L.tileLayer('https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
        maxZoom: 21,
        attribution: '&copy; <a href="https://maps.google.com">Google Maps</a> &copy; <a href="https://www.flaticon.com/free-icons/location" title="location icons">Location icons created by Smashicons - Flaticon</a>'
    }).addTo(map.value);


    for (const element of serviceLocations.value) {
        let marker = L.marker(element.coords, { icon: myIcon }).addTo(map.value).bindTooltip(element.name);
        marker.on("click", () => {
            let googlemapsurl = `https://www.google.com/maps/dir/?api=1&origin=&destination=${element.coords[0]},${element.coords[1]}&travelmode=driving`
            window.open(googlemapsurl, "_blank")
        })
    }
    const overlayDiv = document.querySelector(".overlay");
    const mapDiv = document.querySelector("#map");
    // overlayDiv.addEventListener("wheel", toggleOverlay);
    // mapDiv.addEventListener("wheel", disableZoom);
})

onBeforeUnmount(() => {
    // const overlayDiv = document.querySelector(".overlay");
    // overlayDiv.removeEventListener("wheel", toggleOverlay);
})

const toggleOverlay = (event) => {
    event.preventDefault();
    if (event.metaKey || event.ctrlKey) {
        showOverlay.value = !showOverlay.value;
    }
}

const removeOverlay = () => {
    map.value.removeLayer(overlay);
}

</script>

<style lang="css" scoped>
#map {
    width: 1232px;
    height: 470px;
    z-index: 98;
}

.container {
    position: relative;
    /* To position the overlay within the container */
    width: 1232px;
    height: 470px;
    z-index: 98;
}

.overlay {
    position: relative;
    margin-top: -470px;
    width: 1232px;
    height: 470px;
    /* background-color: rgba(0, 0, 0, 0.5); */
    /* Black with 50% opacity */
    z-index: 99;
    /* Ensures overlay is above content */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 19px;
    color: #fff;
    font-weight: 600;
}

@media(max-width: 600px) {
    #map {
        width: 100%;
        height: 470px;
    }

    .overlay {
        position: relative;
        margin-top: -470px;
        width: 100%;
        height: 470px;
        background-color: rgba(0, 0, 0, 0.2);
        /* Black with 50% opacity */
        z-index: 99;
        /* Ensures overlay is above content */
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 19px;
        color: #fff;
        font-weight: 600;
    }
}
</style>