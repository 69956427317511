<template>
    <n-space justify="center" style="padding-top: 2rem; padding-left: 2rem; padding-right: 2rem;">
        <n-h1>Privacy Policy</n-h1>
    </n-space>
    <n-grid cols="1" item-responsive>
        <n-gi span="1" class="content-gi">
            <n-h2>Overview</n-h2>
            <n-p>
                Your access to, and use of the Ask For Cure LLC (d/b/a Ask For Cure) (“Ask For Cure”) services which
                include this web site <n-a href="askforcure.com">(www.askforcure.com)</n-a> and the information,
                products and services that we provide to
                you and other users through this web site, as well as any mobile applications (“Ask For Cure App(s)”)
                provided by us in connection with our products and services (collectively, the “Ask For Cure Services”)
                is subject to the Ask For Cure Services <n-a href="askforcure.com/terms-of-service">Terms of
                    Service</n-a> and this Privacy Policy.
            </n-p>
            <n-p>
                Ask For Cure has created this Privacy Policy to explain what information we gather from you when you use
                the Ask For Cure Services, how we may use this information, the security approaches we use to protect
                your information, and how you can access and request modification of certain information that we may
                store about you. Please note that this Privacy Policy applies only to information collected via the Ask
                For Cure Services – it does not apply to information you disclose to Ask For Cure through other means.
                This Privacy Policy is incorporated and made part of the Ask For Cure Services <n-a
                    href="/terms-of-service">Terms of Service.</n-a>
            </n-p>
            <n-p><b>Your Acceptance of This Privacy Policy and Changes to It</b></n-p>
            <n-p>
                By accessing, viewing or otherwise using any Ask For Cure Service, you consent to the collection and use
                of your information by Ask For Cure in accordance with this Privacy Policy. If you do not agree to this
                Privacy Policy, you may not use any Ask For Cure Service. Ask For Cure reserves the right to change,
                modify, add or remove portions of this Privacy Policy at any time, without prior notice. Changes take
                effect on the date that change appears on the revised Privacy Policy. If you use any Ask For Cure
                Service following a change in this Privacy Policy, your use will be understood to signal that you accept
                and agree to be bound by the changes. Accordingly, we urge you to review this Privacy Policy frequently
                for changes.
            </n-p>
            <n-p><n-b>What information do we collect?</n-b></n-p>
            <n-p>We collect personally identifiable information (“Personal Information”) and other non-individually
                identifiable information from you when you register on the Ask For Cure Services, respond to
                communication such as e-mail, or otherwise use the Ask For Cure Services in any manner.</n-p>
            <n-p>To participate in certain features of the Ask For Cure Services, you will be required to provide
                certain Personal Information. For example, when registering as a user on any Ask For Cure Service, we
                may ask you for your name, e-mail address, birth date, mailing address, zip code, phone number, credit
                card information or other Personal Information. You may, however, visit portions of the Ask For Cure
                Services without providing any Personal Information.</n-p>
            <n-p>We may collect and use mobile device identifiers, IP addresses and session identifiers to analyze
                trends, to administer the Ask For Cure Services, to track user activities, to infer user interests, and
                to otherwise induce, deduce, and gather information about individual users and market segments.
            </n-p>
            <n-p>If necessary to perform the Ask For Cure Services, we may collect protected health information (“PHI”),
                as defined by the Health Insurance Portability and Accountability Act of 1996 (“HIPAA”). Any use of PHI
                will be in accordance with applicable laws, including HIPAA.</n-p>
            <n-p>Like many web sites and certain mobile apps, we use and may allow third party service providers to use
                “cookies”, flash cookies, local shared objects, web beacons, pixels, single pixel GIFs, clear GIFs,
                and/or other tracking technologies to enhance your experience and gather information about visitors and
                visits to the Ask For Cure Services, including how you use the Ask For Cure Services (“Usage Data”).
                Please refer to the “Do we use 'cookies'?” section below for information about cookies and how we use
                them.</n-p>
            <n-p>We may also collect transactional information, customer services information, or any other legally
                permissible information.</n-p>
            <n-p>We may also collect and store certain other personally non-identifiable information. This information
                is collected passively using various technologies, and cannot presently be used to specifically identify
                you.</n-p>
            <n-p>Your information may be supplemented with additional information from other companies such as publicly
                available information, information about households and other information that we may append or match to
                your information.</n-p>
            <n-p><b>
                    How do we use your information?
                </b></n-p>
            <n-p>
                We may use the Personal Information and other data we collect from you when you register, purchase
                services, respond to a survey or marketing communication, access or view the Ask For Cure Services, or
                use certain other Ask For Cure Service features in the following ways:
            </n-p>
            <n-p>
                <ul>
                    <li>To provide any legitimate business service or product.</li>
                    <li>To provide any legitimate business service or product.</li>
                    <li>To personalize your Ask For Cure Service and to allow us to deliver the type of content and
                        product offerings in which you are most interested (including to present offers to you on behalf
                        of business partners and advertisers).</li>
                    <li>To allow us to better service you in responding to your customer service requests.</li>
                    <li>To contact you when necessary or requested.</li>
                    <li>To quickly process your transactions.</li>
                    <li>To verify and validate your identity.</li>
                    <li>To administer a promotion, survey or other Ask For Cure Service features.</li>
                    <li>To the extent you have not opted out of receiving marketing communications, to send you
                        information about Ask For Cure or our products or the Ask For Cure Services, including by
                        letter, email, text, telephone or other forms of communication.</li>
                    <li>To the extent you have not opted out of receiving marketing communications, to provide you with
                        information about third-party businesses, products and services, including by letter, email,
                        text, telephone or other forms of communication.</li>
                    <li>To show you advertisements, including interest-based or online behavioral advertising.</li>
                    <li>To troubleshoot problems with the Ask For Cure Services, or any services, as requested.</li>
                    <li>To enforce the Terms of Service, and to detect and protect against error, fraud and other
                        unauthorized or illegal activities.</li>
                    <li>To operate and improve the Services available through us.</li>
                    <li>To fulfill your requests for tools, software, functionality, features and other products, and
                        services;</li>
                    <li>To communicate with you and respond to your inquiries.</li>
                    <li>To conduct research about your use of our products; and</li>
                    <li>To help offer you other products, features or services that may be of interest.</li>
                </ul>
            </n-p>
            <n-p>We reserve the right to make full use of Usage Data. For example, we may use Usage Data to provide
                better service to Service visitors or end users, customize the Services based on your preferences,
                compile and analyze statistics and trends about the use of our Services and otherwise administer and
                improve our Services.</n-p>
            <n-p>You understand and agree that if you are using any Ask For Cure Service from a country outside the
                United States and provide Personal Information to the Ask For Cure Service, you will be authorizing and
                consenting to the transfer of Personal Information about yourself to the United States. You understand
                that the privacy laws of the United States may be different from and not as comprehensive or protective
                as those in your country, and you agree that the transfer of your Personal Information to the United
                States occurs with your consent. Personal Information collected on the Ask For Cure Service may be
                stored and processed in the United States or abroad.</n-p>
            <n-p><b>Do we disclose the information we collect to outside parties?</b></n-p>
            <n-p>You understand and agree that we can disclose your Personal Information and other data to third
                parties, as follows:</n-p>
            <n-p>
                <ul>
                    <li>To our subsidiaries or affiliates, strategic partners and to third parties we engage to provide
                        services, such as medical services, web site hosting, credit card payment processing, order
                        processing, analytics services etc.</li>
                    <li> In the event of the sale or transfer of Ask For Cure, of one or more of our business units or
                        of some or all of our assets, or in the context of some other business acquisition transaction.
                    </li>
                    <li> In response to lawful governmental requests or legal process (for example a subpoena, court
                        order, or search warrant); to establish or exercise our legal rights, or to defend against
                        claims; or to protect the safety or security of the public or of users of the Ask For Cure
                        Services; or in other circumstances in which we have a good-faith belief that a crime has been
                        or is being committed by a user of our Services, that an emergency exists that poses a threat to
                        the safety of you or another person,</li>
                    <li> When necessary to protect either our rights or our property or for us to render the service you
                        have requested.</li>
                    <li> We can disclose aggregate information and other non-individually identifiable information about
                        users of the Ask For Cure Service to our service providers, partners, advertisers or others. For
                        example, we may share information publicly to show trends about the general use of our websites
                        and/or other products or services.</li>
                </ul>
            </n-p>
            <n-p><b>
                    Correcting and Updating Your Personal Information
                </b></n-p>
            <n-p>You can correct or update your Personal Information by re-registering or by contacting us by e-mail at
                <n-a href="mailto:support@askforcure.com">support@askforcure.com</n-a>. It may take us up to 30 days to
                process your request.</n-p>
            <n-p><b>Do we use “cookies”?</b></n-p>
            <n-p>Yes. Cookies are small files that a site or its service provider transfers to your computer’s hard
                drive through your Web browser or mobile device that enables the site’s or service provider’s systems to
                recognize your browser and capture and remember certain information. Cookies help us in many ways to
                make your use of the Ask For Cure Services more enjoyable and meaningful, such as understanding usage
                patterns and improving functionality of the Ask For Cure Services. For instance, we use cookies to help
                us understand your preferences based on previous or current Ask For Cure Services activity, which
                enables us to provide you with improved services. We also use cookies to help us compile aggregate data
                about Ask For Cure Services traffic and Ask For Cure Services interaction so that we can offer better
                Ask For Cure Services experiences and tools in the future.</n-p>
            <n-p>We may contract with third-party service providers to assist us in better understanding our Ask For
                Cure Service visitors. For example, we use Google Analytics, a web analytics service provided by Google,
                Inc. (“Google”). Google Analytics uses cookies to help us analyze how users use the Ask For Cure
                Services and enhance your experience when you use the Ask For Cure Services. For more information on how
                Google uses this data, go to <n-a
                    href="https://policies.google.com/technologies/partner-sites">www.google.com/policies/privacy/partners/</n-a>.</n-p>
            <n-p>If you are using our website, you can choose to have your computer warn you each time a cookie is being
                sent, or you can choose to turn off all cookies. You do this through your browser settings. If you are
                accessing our mobile app, you can choose to change your permissions and settings on your mobile device.
                Each browser and device is different, so look at your browser or devices Help or Settings menu to learn
                the correct way to modify them. If you turn cookies off or disable certain permissions, you won’t have
                access to many features that make your Ask For Cure Service more efficient and some of our services will
                not function properly.</n-p>
            <n-p><b>"Do Not Track"</b></n-p>
            <n-p>
                Our Ask For Cure Services currently do not respond to “Do Not Track” (DNT) signals and do not recognize
                browser-based “do-not-track” requests.
            </n-p>
            <n-p><b>Safeguarding Your Personal Information Privacy and Third Party Links</b></n-p>
            <n-p>Ask For Cure follows generally accepted industry security standards to safeguard and help prevent
                unauthorized access and maintain data security of Personal Information. However, no commercial method of
                information transfer over the Internet or electronic data storage is known to be 100% secure. As a
                result, we cannot guarantee the absolute security of any Personal Information submitted to or otherwise
                collected during your use of any Ask For Cure Service. Accordingly, you understand and agree that you
                transmit all data, including Personal Information, to us at your own risk.</n-p>
            <n-p><b>Privacy and Third Party Links</b></n-p>
            <n-p>This Privacy Policy applies solely to information collected by the Ask For Cure Service through our web
                site located at askforcure.com and the mobile application(s) we make available. In an attempt to provide
                you with increased value, we may include third party links on the Ask For Cure Services. This Privacy
                Policy does not apply to the third party sites and services that are accessible through these links and
                we suggest that you contact the operator of the third party service to obtain details about their
                privacy policies. We have no responsibility or liability for the content and activities of these linked
                sites or services. Nonetheless, we seek to protect the integrity of the Ask For Cure Services and
                welcome any feedback about these linked sites (including if a specific link does not work).</n-p>
            <n-p><b>Exclusions</b></n-p>
            <n-p>This Privacy Policy shall not apply to any unsolicited information you provide to us through the Ask
                For Cure Services or through any other means. This includes, but is not limited to, any ideas for new
                products or modifications to existing products, and other unsolicited submissions (collectively,
                “Unsolicited Information”). All Unsolicited Information shall be deemed to be non-confidential and we
                shall be free to reproduce, use, disclose, and distribute such Unsolicited Information to others without
                limitation or attribution.</n-p>

            <n-p><b>Children's Privacy</b></n-p>
            <n-p>Only individuals 18 years of age or older are authorized to use the Ask For Cure Services, either for
                themselves or on behalf of their minor children. We do not knowingly collect information directly from
                children under the age of eighteen. If you have reason to believe that an individual under the age of 18
                has provided Personal Information to us through a Ask For Cure Service, please contact us, and we will
                endeavor to delete that information from our databases.</n-p>
            <n-p><b>Retention of Your Personal Information</b></n-p>
            <n-p>We will store the Personal Information you provide for as long as we believe is necessary or
                appropriate (i) to carry out the purpose(s) for which we collected it, or (ii) to comply with applicable
                laws, contracts, or other rules or regulations.</n-p>
            <n-p><b>Questions and Feedback</b></n-p>
            <n-p>We welcome your questions, comments, and concerns about the Ask For Cure Services. Please send us any
                and all feedback pertaining to the Ask For Cure Services to <n-a
                    href="mailto:support@askforcure.com">support@askforcure.com</n-a>.</n-p>
            <n-p><b>YOUR CALIFORNIA PRIVACY RIGHTS</b></n-p>
            <n-p>For California residents: We may share your personal information with third parties and affiliated
                third parties (such as local, state and regional affiliates and affiliate alliances), some of which do
                not share the We name, for their direct marketing purposes. As these third parties and this category of
                affiliated third parties are considered an unaffiliated party under California law, you may opt-out of
                our disclosure of personal information to third parties for their direct marketing purposes. To opt out,
                please contact us as described in the “How to Contact Us” Section below.</n-p>
            <n-p>We will continue to provide your information to local, state and regional affiliates and affiliate
                alliances identified in your membership application or account for the purpose of processing your
                membership in such affiliate and affiliate alliance programs.</n-p>
            <n-p><b>California Consumer Privacy Act</b></n-p>
            <n-p>
                The California Consumer Privacy Act (CCPA), effective January 1, 2020, gives California consumers
                enhanced rights with respect to their personal information that is collected by businesses. First,
                California consumers may opt out of having their personal information sold to other persons or parties.
                Second, they have a right to know:
            </n-p>
            <n-p>
                <ol>
                    <li> What specific pieces of information a business has about the consumer;</li>
                    <li> Categories of personal information it has collected about the consumer;</li>
                    <li> Categories of sources from which the personal information is collected;</li>
                    <li> Categories of personal information that the business sold or disclosed for a business purpose
                        about the consumer;</li>
                    <li> Categories of third parties to whom the personal information was sold or disclosed for a
                        business purpose; and</li>
                    <li> The business or commercial purpose for collecting or selling personal information.</li>
                </ol>
            </n-p>
            <n-p>
                In addition, California consumers can request that the personal information a business has collected
                about them be deleted from the business’s systems and records.</n-p>
            <n-p>Company may be considered a covered business under the CCPA as it collects and processes the personal
                information of California consumers. This Privacy Policy provides the required notices to California
                consumers. The CCPA also prohibits covered businesses from providing discriminatory treatment to
                California consumers if they exercise their rights under the Act.</n-p>
            <n-p>We do not rent or sell your personally identifiable information (such as name, address, telephone
                number and credit card information) to unaffiliated third parties for their marketing purposes. We may
                share your information with third parties to provide products and services you have requested, when we
                have your consent, or as described in this Privacy Policy.</n-p>
            <n-p>To make a “request to know” or request to delete your personal information, send us an e-mail at
                <n-a href="mailto:support@askforcure.com">support@askforcure.com</n-a> (Please put either “Request to
                Know” or “Request to Delete” in the subject
                heading of your email.) We will use commercially reasonable efforts to honor these requests whether or
                not you would qualify as a California consumer under the CCPA.</n-p>
            <n-p>If and to the extent we are considered a covered business under the CCPA: We will confirm receipt of
                your request within 10 days along with a description of what steps we will take to verify and respond.
                We must provide the requested information or delete your personal information within 45 days of receipt
                of your request but can use an additional 45 days, but we must let you know the additional time is
                needed.</n-p>
            <n-p>When contacting us, we may ask you to provide certain, limited personal information, such as your name,
                email address and/or account login ID and/or password, to verify your request and to match with our
                records and systems. This is also to protect against fraud. We will not retain this personal information
                or use it for any other purpose. Also please be advised that we need to search our records and systems
                only for the preceding 12 months.</n-p>
            <n-p>Residents of other states may also have similar rights to request information about or delete their
                personal information. To inquire about exercising these rights, please contact us at
                <n-a href="mailto:support@askforcure.com">support@askforcure.com</n-a>.</n-p>
            <n-p>Pursuant to California’s “Shine The Light law (California Civil Code § 1798.983), California residents
                are entitled, once a year and free of charge, to request the disclosure of certain categories of
                personal information to third parties for their own direct marketing purposes in the preceding calendar
                year, if any. Under the law, a business should either provide California customers certain information
                upon request or permit California customers to opt out of this type of sharing. You may request this
                information by contacting us at <n-a href="mailto:support@askforcure.com">support@askforcure.com</n-a>
                and indicate in the email subject line,
                “California Shine The Light Request.” Please include your mailing address, state of residence and email
                address with your request.</n-p>
            <n-p><b>Changes to this Privacy Policy</b></n-p>
            <n-p>We may update this Privacy Policy from time to time, and so you should review this Policy periodically.
                Your continued use of any Services constitutes your acceptance of any such changes.</n-p>
        </n-gi>
    </n-grid>

</template>

<script setup lang="js">
</script>

<style lang="css" scoped>
.content-gi {
    text-align: left;
    padding-left: 15rem;
    padding-right: 15rem;
    padding-bottom: 10rem;
}

@media(max-width: 600px) {
    .content-gi {
        text-align: center;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 10rem;
    }
}
</style>