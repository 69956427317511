<template>
    <n-grid cols="1" item-responsive>
        <n-gi class="review-gi">
            <div class="review-section">
                <n-h1>FASTEST & BEST PRICED I693 EXAM!</n-h1>
                <Reviews />
            </div>
        </n-gi>
        <n-gi class="appointment-banner-gi">
            <n-alert class="appointment-banner cursor-pointer" @click="scrollToAppointmentSection" :show-icon="false">
                <n-text>
                    Book Your Appointment With Dr. Vinit Agrawal. Text 765-327-8378 for appointment.
                </n-text>
            </n-alert>
        </n-gi>
        <n-gi style="padding-left: 2rem; padding-right: 2rem; align-items: center; text-align: center;">
            <n-space vertical style="min-height: 500px;">
                <!-- {{currentLanguage}} -->
                <n-h1 class="block-case" style="padding-bottom: 2rem;">
                    I693 explained in 5 minutes - Must watch Video!
                </n-h1>
                <n-space :vertical="isMobile() ? true: false" justify="center" :size="[35, 5]"
                    style="align-items: center; width: 100%">
                    <iframe class="video-iframe"
                        :src="embeddedLanguageVideoMap[currentLanguage] ?? embeddedLanguageVideoMap['en']"
                        title="YouTube video player" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen>
                    </iframe>
                    <n-space vertical style="text-align: left">
                        <n-h2>Immigration Medical Exam in 3 Steps:</n-h2>
                        <n-list class="three-steps-list">
                            <n-list-item>
                                1. Get a list of <a
                                    :href="'?lang='+currentLanguage+'#requirements-check-section'">required
                                    documents</a>.
                            </n-list-item>
                            <n-list-item>
                                <n-text>2. <a href="mailto:support@askforcure.com">Email</a> or <n-text strong><a
                                            href="#request-an-appointment">Upload</a></n-text>
                                    the documents.</n-text>
                            </n-list-item>
                            <n-list-item>
                                3. Finish I693 in person ASAP!
                            </n-list-item>
                        </n-list>
                        <n-p class="font-18" style="margin-top: 2rem; font-weight: 700">Available NOW, depending on
                            location. </n-p>
                        <n-p class="font-18" style="margin-top: 1rem;"><b>ANY DAY, ANY TIME</b>
                            APPOINTMENT!<sup>*</sup></n-p>
                        <n-p class="font-18" style="margin-top: 1rem; font-style: italic"><sup>*</sup>Subject to
                            availability.</n-p>
                    </n-space>
                </n-space>
            </n-space>
        </n-gi>
        <n-gi span="1" style="align-items: center; text-align: center; display: flex; justify-content: center;"
            class="lms">
            <div style="min-height: 600px; padding-top: 5rem;">
                <n-h1 class="block-case" style="padding-bottom: 2rem;">
                    i693 uscis civil surgeon immigration medical exam LOCATIONS
                </n-h1>
                <n-space vertical>
                    <ServiceLocationMapView />
                </n-space>
            </div>
        </n-gi>
        <!-- <n-gi
            style="padding-left: 2rem; padding-right: 2rem; align-items: center; text-align: center; display: flex; justify-content: center; background-color: #F6F6F6">
            <div style="height: 200px; padding-top: 3rem; padding-bottom: 3rem">
                <n-space vertical justify="space-between" class="section-4">
                    <n-a class="font-20 underline"
                        href="https://app.formdr.com/practice/MTY2NzA=/form/Dm0hk0SuMNXJ6fuBDvXPMJ72d_vIRL2H">CLICK HERE
                        TO START IMMIGRATION MEDICAL EXAM TODAY!</n-a>
                    <n-text class="font-20">All the required <b>labs</b> & <b>vaccines</b> are listed below based on
                        age. </n-text>
                    <n-text></n-text>
                </n-space>
            </div>
        </n-gi> -->
        <n-gi id="requirements-check-section">
            <n-divider></n-divider>
        </n-gi>
        <n-gi
            style="padding-left: 2rem; padding-right: 2rem; align-items: center; text-align: center; display: flex; justify-content: center;">
            <div id="requirements-check-section."
                style="min-height: 200px; padding-top: 3rem; padding-bottom: 3rem; width: 100%">
                <n-h2 style="font-weight: 400;">Please enter your birthdate to get the list of required vaccines and lab
                    tests for your age.</n-h2>
                <n-form ref="formRef" :model="formValue" :rules="rules" :validate-messages="validateMessages">
                    <n-grid cols="10" item-responsive x-gap="10" y-gap="1">
                        <n-gi span="10 600:4" style="text-align: center; justify-self: center">
                            <n-form-item :show-label="false" path="user.firstName"
                                :style="isMobile() ? 'text-align: right': 'justify-items: right'">
                                <n-input placeholder="First Name" v-model:value="formValue.user.firstName"
                                    style="width: 300px; text-align: center;"></n-input>
                            </n-form-item>
                        </n-gi>
                        <n-gi span="10 600:2" style="text-align: center; justify-self: center">
                            <n-form-item :show-label="false" path="user.middleName" style="text-align: center">
                                <n-input placeholder="Middle Name" v-model:value="formValue.user.middleName"
                                    style="width: 300px; text-align: center;"></n-input>
                            </n-form-item>
                        </n-gi>
                        <n-gi span="10 600:4" style="text-align: left; justify-self: center">
                            <n-form-item :show-label="false" path="user.lastName" style="text-align: left">
                                <n-input placeholder="Last Name" v-model:value="formValue.user.lastName"
                                    style="width: 300px; text-align: center;"></n-input>
                            </n-form-item>
                        </n-gi>
                        <n-gi span="10 600:4" style="text-align: right; justify-self: center">
                            <n-form-item :show-label="false" path="user.emailId"
                                :style="isMobile() ? 'text-align: center': 'justify-items: right'">
                                <n-input placeholder="EmailId" v-model:value="formValue.user.emailId"
                                    :input-props="{type: 'email'}" style="width: 300px; text-align: center;"></n-input>
                            </n-form-item>
                        </n-gi>
                        <n-gi span="10 600:2" style="text-align: center; justify-self: center">
                            <!-- <n-button primary type="primary" style="width: 300px">Request an Appointment</n-button> -->
                            <n-form-item :show-label="false" path="user.contactNumber" style="text-align: center">
                                <n-input id="contact-number" placeholder="Contact no"
                                    v-model:value="formValue.user.contactNumber"
                                    style="width: 300px; text-align: center;" type="number">
                                    <template #prefix>
                                        +1
                                    </template>
                                </n-input>
                            </n-form-item>
                        </n-gi>
                        <n-gi span="10 600:4" style="text-align: center; justify-self: center">
                            <!-- <VueDatePicker :type="date" v-model="birthDate" style="max-width: 300px;" placeholder="Birth Date" /> -->
                            <!-- <n-date-picker type="date" style="max-width: 300px;" v-model:value="birthDate"
                        @update:value="calculateAge"></n-date-picker> -->
                            <n-form-item :show-label="false" path="user.dateOfBirth" class="dob-picker">
                                <!--n-input-group>
                                    <n-input placeholder="mm" type="number" v-model:value="birthMonth"
                                        @input="validateBirthDateEntries"></n-input>
                                    <n-input placeholder="dd" type="number" v-model:value="birthDay"
                                        @input="validateBirthDateEntries"></n-input>
                                    <n-input placeholder="yyyy" type="number" v-model:value="birthYear"
                                        @input="validateBirthDateEntries"></n-input>
                                </n-input-group-->
                                <input id="dob" placeholder="Date of birth" readonly
                                    style="width: 100%; height: 30px; border: 1px solid #E0E0E6; padding-left: 12px; padding-right: 12px; text-align: center; border-radius: 3px;">
                                </input>
                                <!-- <input id="dob" placeholder="Date of birth"
                                    > -->
                                </input>
                            </n-form-item>
                        </n-gi>
                        <n-gi span="10" style="text-align: center; justify-self: center">
                            <n-button primary type="primary" @click="triggerConversation"
                                style="max-width: 250px; white-space: wrap; padding-top: 1.5rem; padding-bottom: 1.5rem;">
                                <template #icon>
                                    <n-icon>
                                        <Mail24Filled />
                                    </n-icon>
                                </template>
                                See Tests List
                            </n-button><br />
                            <n-text class="mini-text">An email would be sent to you with the checklist details.</n-text>
                        </n-gi>
                        <n-gi span="10" id="lala">
                        </n-gi>
                    </n-grid>
                </n-form>
                <n-card style="margin-top: 3rem" id="required-documents-card" v-if="emailSent">
                    <n-grid cols="1" style="text-align: left;" item-responsive>
                        <n-gi span="1" class="required-documents-card-gi" v-if="chosenAgeGroup === 'age-group-0'">
                            <div class="age-group-0 invisible">
                                <div class="no-bold">
                                    <h4>Vaccines needed</h4>
                                    <p class="less-line-height">- Please email your child's vaccine records to <a
                                            href="mailto:support@askforcure.com">support@askforcure.com</a></p>
                                    <p class="">We will review it and get back to you ASAP!</p>
                                </div>
                            </div>
                        </n-gi>
                        <n-gi span="1" class="required-documents-card-gi" v-if="chosenAgeGroup === 'age-group-1'">
                            <div class="age-group-1 invisible">

                                <div class="no-bold">
                                    <h4>Vaccines needed</h4>
                                    <p class="less-line-height">- DTaP vaccine</p>
                                    <p class="less-line-height">- MMR vaccine</p>
                                    <p class="less-line-height">- Hepatitis A vaccine</p>
                                    <p class="less-line-height">- Hepatitis B vaccine</p>
                                    <p class="less-line-height">- HiB vaccine</p>
                                    <p class="less-line-height">- Pneumococcal vaccine</p>
                                    <p class="less-line-height">- Polio Vaccine</p>
                                    <p class="less-line-height">- Varicella (chickenpox) vaccine or immunity proof</p>
                                    <p class="less-line-height">- COVID-19 new vaccine in 2024 - any number of prior
                                        doses
                                        taken before 2024 are
                                        irrelevant at this point</p>
                                    <p class="less-line-height">- Flu Shot in August 2024 or later</p>


                                    <p>You can go to your kid's doctor's office and get these done. We don’t sell
                                        vaccines
                                        at our offices and we
                                        don’t collect payment for that.</p>

                                    <h4>Lab tests needed to be ordered by civil surgeon</h4>
                                    <p class="less-line-height">- Quantiferon TB Gold</p>

                                    <p>You can purchase the above labs/immunity tests for the cheapest price in
                                        California
                                        at:</p>
                                    <p class="less-line-height">
                                        <b>Lab test links:</b> <a :href="checkoutLink" target="_blank">Click
                                            here</a>
                                    </p>
                                    <p class="less-line-height"><b>Immunity test links:</b> <a :href="checkoutLink"
                                            target="_blank">Click
                                            here</a></p>
                                </div>
                            </div>
                        </n-gi>
                        <n-gi span="1" class="required-documents-card-gi" v-if="chosenAgeGroup === 'age-group-10'">
                            <div class="age-group-10 invisible">

                                <div class="no-bold">
                                    <h4>Vaccines needed</h4>
                                    <p class="less-line-height">- DTaP vaccine</p>
                                    <p class="less-line-height">- MMR vaccine</p>
                                    <p class="less-line-height">- Hepatitis A vaccine</p>
                                    <p class="less-line-height">- Hepatitis B vaccine</p>
                                    <p class="less-line-height">- Polio Vaccine</p>
                                    <p class="less-line-height">- Varicella (chickenpox) vaccine or immunity proof</p>
                                    <p class="less-line-height">- COVID-19 new vaccine in 2024 - any number of prior
                                        doses
                                        taken before 2024 are
                                        irrelevant at this point</p>
                                    <p class="less-line-height">- Flu Shot in August 2024 or later</p>

                                    <p>You can go to your kid's doctor's office and get these done. We don’t sell
                                        vaccines
                                        at our offices and we
                                        don’t collect payment for that.</p>

                                    <h4>Lab tests needed to be ordered by civil surgeon</h4>
                                    <p class="less-line-height">- Quantiferon TB Gold</p>

                                    <p>You can purchase the above labs/immunity tests for the cheapest price in
                                        California
                                        at:</p>
                                    <p class="less-line-height">
                                        <b>Lab test links:</b> <a :href="checkoutLink" target="_blank">Click
                                            here</a>
                                    </p>
                                    <p class="less-line-height"><b>Immunity test links:</b> <a :href="checkoutLink"
                                            target="_blank">Click
                                            here</a></p>
                                </div>
                            </div>
                        </n-gi>
                        <n-gi span="1" class="required-documents-card-gi" v-if="chosenAgeGroup === 'age-group-2'">
                            <div class="age-group-2 invisible">
                                <div class="no-bold">
                                    <h4>Vaccines needed</h4>
                                    <p class="less-line-height">- Tdap or DTaP vaccine</p>
                                    <p class="less-line-height">- MMR vaccine</p>
                                    <p class="less-line-height">- Hepatitis A vaccine</p>
                                    <p class="less-line-height">- Hepatitis B vaccine</p>
                                    <p class="less-line-height">- Polio Vaccine</p>
                                    <p class="less-line-height">- Varicella (chickenpox) vaccine or immunity proof</p>
                                    <p class="less-line-height">- COVID-19 new vaccine in 2024 - any number of prior
                                        doses
                                        taken before 2024 are
                                        irrelevant at this point</p>
                                    <p class="less-line-height">- Flu Shot in August 2024 or later</p>


                                    <p>You can go to any pharmacy for this and get them. No doctor's orders are needed
                                        for
                                        these. We don’t sell vaccines at our offices and We don’t collect payment for
                                        that.
                                        You can use your insurance directly at the pharmacy and pay directly to them.
                                    </p>

                                    <h4>Lab tests needed to be ordered by civil surgeon</h4>
                                    <p class="less-line-height">- Quantiferon TB Gold</p>

                                    <p>You can purchase the above labs/immunity tests for the cheapest price in
                                        California
                                        at:</p>
                                    <p class="less-line-height">
                                        <b>Lab test links:</b> <a :href="checkoutLink" target="_blank">Click
                                            here</a>
                                    </p>
                                    <p class="less-line-height"><b>Immunity test links:</b> <a :href="checkoutLink"
                                            target="_blank">Click
                                            here</a></p>
                                </div>
                            </div>
                        </n-gi>
                        <n-gi span="1" class="required-documents-card-gi" v-if="chosenAgeGroup === 'age-group-3'">
                            <div class="age-group-3 invisible">
                                <div class="no-bold">
                                    <h4>Vaccines needed</h4>
                                    <p class="less-line-height">- Tdap within last 10 years</p>
                                    <p class="less-line-height">- MMR vaccine</p>
                                    <p class="less-line-height">- Hepatitis A vaccine</p>
                                    <p class="less-line-height">- Hepatitis B vaccine</p>
                                    <p class="less-line-height">- Meningococcal vaccine</p>
                                    <p class="less-line-height">- Polio Vaccine</p>
                                    <p class="less-line-height">- Varicella (chickenpox) vaccine or immunity proof</p>
                                    <p class="less-line-height">- COVID-19 new vaccine in 2024 - any number of prior
                                        doses
                                        taken before 2024 are
                                        irrelevant at this point</p>
                                    <p class="less-line-height">- Flu Shot in August 2024 or later</p>


                                    <p>You can go to any pharmacy for this and get them. No doctor's orders are needed
                                        for
                                        these. We don’t sell vaccines at our offices and We don’t collect payment for
                                        that.
                                        You can use your insurance directly at the pharmacy and pay directly to them.
                                    </p>

                                    <h4>Lab tests needed to be ordered by civil surgeon</h4>
                                    <p class="less-line-height">- Quantiferon TB Gold</p>

                                    <p>You can purchase the above labs/immunity tests for the cheapest price in
                                        California
                                        at:</p>
                                    <p class="less-line-height">
                                        <b>Lab test links:</b> <a :href="checkoutLink" target="_blank">Click
                                            here</a>
                                    </p>
                                    <p class="less-line-height"><b>Immunity test links:</b> <a :href="checkoutLink"
                                            target="_blank">Click
                                            here</a></p>
                                </div>
                            </div>
                        </n-gi>
                        <n-gi span="1" class="required-documents-card-gi" v-if="chosenAgeGroup === 'age-group-4'">
                            <div class="age-group-4 invisible">
                                <div class="no-bold">
                                    <h4>Vaccines needed</h4>
                                    <p class="less-line-height">- Tdap within last 10 years</p>
                                    <p class="less-line-height">- MMR vaccine or immunity proof</p>
                                    <p class="less-line-height">- Hepatitis B vaccine or immunity proof</p>
                                    <p class="less-line-height">- Polio Vaccine - No immunity tests are acceptable for
                                        polio.
                                        You will have to get one dose of polio vaccine again if you don't have childhood
                                        vaccination records</p>
                                    <p class="less-line-height">- Varicella (chickenpox) vaccine or immunity proof</p>
                                    <p class="less-line-height">- COVID-19 new vaccine in 2024 - any number of prior
                                        doses
                                        taken before 2024 are
                                        irrelevant at this point</p>
                                    <p class="less-line-height">- Flu Shot in August 2024 or later</p>


                                    <p>You can go to any pharmacy for this and get them. No doctor's orders are needed
                                        for
                                        these. We don’t sell vaccines at our offices and We don’t collect payment for
                                        that.
                                        You can use your insurance directly at the pharmacy and pay directly to them.
                                    </p>

                                    <h4>Lab tests needed to be ordered by civil surgeon</h4>
                                    <p class="less-line-height">- Quantiferon TB Gold</p>
                                    <p class="less-line-height">- Syphilis test</p>
                                    <p class="less-line-height">- Gonorrhea test</p>

                                    <p>You can purchase the above labs/immunity tests for the cheapest price in
                                        California
                                        at:</p>
                                    <p class="less-line-height">
                                        <b>Lab test links:</b> <a :href="checkoutLink" target="_blank">Click
                                            here</a>
                                    </p>
                                    <p class="less-line-height"><b>Immunity test links:</b> <a :href="checkoutLink"
                                            target="_blank">Click
                                            here</a></p>
                                </div>
                            </div>
                        </n-gi>
                        <n-gi span="1" class="required-documents-card-gi" v-if="chosenAgeGroup === 'age-group-5'">
                            <div class="age-group-5 invisible">
                                <div class="no-bold">
                                    <h4>Vaccines needed</h4>
                                    <p class="less-line-height">- Tdap within last 10 years</p>
                                    <p class="less-line-height">- MMR vaccine or immunity proof</p>
                                    <p class="less-line-height">- Hepatitis B vaccine or immunity proof</p>
                                    <p class="less-line-height">- Polio Vaccine - No immunity tests are acceptable for
                                        polio.
                                        You will have to get one dose of polio vaccine again if you don't have childhood
                                        vaccination records</p>
                                    <p class="less-line-height">- Varicella (chickenpox) vaccine or immunity proof</p>
                                    <p class="less-line-height">- COVID-19 new vaccine in 2024 - any number of prior
                                        doses
                                        taken before 2024 are
                                        irrelevant at this point</p>
                                    <p class="less-line-height">- Flu Shot in August 2024 or later</p>


                                    <p>You can go to any pharmacy for this and get them. No doctor's orders are needed
                                        for
                                        these. We don’t sell vaccines at our offices and We don’t collect payment for
                                        that.
                                        You can use your insurance directly at the pharmacy and pay directly to them.
                                    </p>

                                    <h4>Lab tests needed to be ordered by civil surgeon</h4>
                                    <p class="less-line-height">- Quantiferon TB Gold</p>
                                    <p class="less-line-height">- Syphilis test</p>

                                    <p>You can purchase the above labs/immunity tests for the cheapest price in
                                        California
                                        at:</p>
                                    <p class="less-line-height">
                                        <b>Lab test links:</b> <a :href="checkoutLink" target="_blank">Click
                                            here</a>
                                    </p>
                                    <p class="less-line-height"><b>Immunity test links:</b> <a :href="checkoutLink"
                                            target="_blank">Click
                                            here</a></p>
                                </div>
                            </div>
                        </n-gi>
                        <n-gi span="1" class="required-documents-card-gi" v-if="chosenAgeGroup === 'age-group-6'">
                            <div class="age-group-6 invisible">
                                <div class="no-bold">
                                    <h4>Vaccines needed</h4>
                                    <p class="less-line-height">- Tdap within last 10 years</p>
                                    <p class="less-line-height">- MMR vaccine or immunity proof</p>
                                    <p class="less-line-height">- Hepatitis B vaccine or immunity proof</p>
                                    <p class="less-line-height">- Polio Vaccine - No immunity tests are acceptable for
                                        polio.
                                        You will have to get one dose of polio vaccine again if you don't have childhood
                                        vaccination records</p>
                                    <p class="less-line-height">- Varicella (chickenpox) vaccine or immunity proof</p>
                                    <p class="less-line-height">- COVID-19 new vaccine in 2024 - any number of prior
                                        doses
                                        taken before 2024 are
                                        irrelevant at this point</p>
                                    <p class="less-line-height">- Flu Shot in August 2024 or later</p>


                                    <p>You can go to any pharmacy for this and get them. No doctor's orders are needed
                                        for
                                        these. We don’t sell vaccines at our offices and We don’t collect payment for
                                        that.
                                        You can use your insurance directly at the pharmacy and pay directly to them.
                                    </p>

                                    <h4>Lab tests needed to be ordered by civil surgeon</h4>
                                    <p class="less-line-height">- Quantiferon TB Gold</p>

                                    <p>You can purchase the above labs/immunity tests for the cheapest price in
                                        California
                                        at:</p>
                                    <p class="less-line-height">
                                        <b>Lab test links:</b> <a :href="checkoutLink" target="_blank">Click
                                            here</a>
                                    </p>
                                    <p class="less-line-height"><b>Immunity test links:</b> <a :href="checkoutLink"
                                            target="_blank">Click
                                            here</a></p>
                                </div>
                            </div>
                        </n-gi>
                        <n-gi span="1" class="required-documents-card-gi" v-if="chosenAgeGroup === 'age-group-7'">
                            <div class="age-group-7 invisible">
                                <div class="no-bold">
                                    <h4>Vaccines needed</h4>
                                    <p class="less-line-height">- Tdap within last 10 years</p>
                                    <p class="less-line-height">- MMR vaccine or immunity proof</p>
                                    <p class="less-line-height">- Polio Vaccine - No immunity tests are acceptable for
                                        polio.
                                        You will have to get one dose of polio vaccine again if you don't have childhood
                                        vaccination records</p>
                                    <p class="less-line-height">- Varicella (chickenpox) vaccine or immunity proof</p>
                                    <p class="less-line-height">- COVID-19 new vaccine in 2024 - any number of prior
                                        doses
                                        taken before 2024 are
                                        irrelevant at this point</p>
                                    <p class="less-line-height">- Flu Shot in August 2024 or later</p>


                                    <p>You can go to any pharmacy for this and get them. No doctor's orders are needed
                                        for
                                        these. We don’t sell vaccines at our offices and We don’t collect payment for
                                        that.
                                        You can use your insurance directly at the pharmacy and pay directly to them.
                                    </p>

                                    <h4>Lab tests needed to be ordered by civil surgeon</h4>
                                    <p class="less-line-height">- Quantiferon TB Gold</p>

                                    <p>You can purchase the above labs/immunity tests for the cheapest price in
                                        California
                                        at:</p>
                                    <p class="less-line-height">
                                        <b>Lab test links:</b> <a :href="checkoutLink" target="_blank">Click
                                            here</a>
                                    </p>
                                    <p class="less-line-height"><b>Immunity test links:</b> <a :href="checkoutLink"
                                            target="_blank">Click
                                            here</a></p>
                                </div>
                            </div>
                        </n-gi>
                        <n-gi span="1" class="required-documents-card-gi" v-if="chosenAgeGroup === 'age-group-8'">
                            <div class="age-group-8 invisible">
                                <div class="no-bold">
                                    <h4>Vaccines needed</h4>
                                    <p class="less-line-height">- Tdap within last 10 years</p>
                                    <p class="less-line-height">- MMR vaccine or immunity proof</p>
                                    <p class="less-line-height">- Prevnar 20 vaccine for Pneumonia</p>
                                    <p class="less-line-height">- Polio Vaccine - No immunity tests are acceptable for
                                        polio.
                                        You will have to get one dose of polio vaccine again if you don't have childhood
                                        vaccination records</p>
                                    <p class="less-line-height">- Varicella (chickenpox) vaccine or immunity proof</p>
                                    <p class="less-line-height">- COVID-19 new vaccine in 2024 - any number of prior
                                        doses
                                        taken before 2024 are
                                        irrelevant at this point</p>
                                    <p class="less-line-height">- Flu Shot in August 2024 or later</p>


                                    <p>You can go to any pharmacy for this and get them. No doctor's orders are needed
                                        for
                                        these. We don’t sell vaccines at our offices and We don’t collect payment for
                                        that.
                                        You can use your insurance directly at the pharmacy and pay directly to them.
                                    </p>

                                    <h4>Lab tests needed to be ordered by civil surgeon</h4>
                                    <p class="less-line-height">- Quantiferon TB Gold</p>

                                    <p>You can purchase the above labs/immunity tests for the cheapest price in
                                        California
                                        at:</p>
                                    <p class="less-line-height">
                                        <b>Lab test links:</b> <a :href="checkoutLink" target="_blank">Click
                                            here</a>
                                    </p>
                                    <p class="less-line-height"><b>Immunity test links:</b> <a :href="checkoutLink"
                                            target="_blank">Click
                                            here</a></p>
                                </div>
                            </div>
                        </n-gi>
                        <n-gi span="1" class="required-documents-card-gi" v-if="chosenAgeGroup === 'age-group-9'">
                            <div class="age-group-9 invisible">
                                <div class="no-bold">
                                    <h4>Vaccines needed</h4>
                                    <p class="less-line-height">- Tdap within last 10 years</p>
                                    <p class="less-line-height">- Prevnar 20 vaccine for Pneumonia</p>
                                    <p class="less-line-height">- Polio Vaccine - No immunity tests are acceptable for
                                        polio.
                                        You will have to get one dose of polio vaccine again if you don't have childhood
                                        vaccination records</p>
                                    <p class="less-line-height">- Varicella (chickenpox) vaccine or immunity proof</p>
                                    <p class="less-line-height">- COVID-19 new vaccine in 2024 - any number of prior
                                        doses
                                        taken before 2024 are
                                        irrelevant at this point</p>
                                    <p class="less-line-height">- Flu Shot in August 2024 or later</p>


                                    <p>You can go to any pharmacy for this and get them. No doctor's orders are needed
                                        for
                                        these. We don’t sell vaccines at our offices and We don’t collect payment for
                                        that.
                                        You can use your insurance directly at the pharmacy and pay directly to them.
                                    </p>

                                    <h4>Lab tests needed to be ordered by civil surgeon</h4>
                                    <p class="less-line-height">- Quantiferon TB Gold</p>

                                    <p>You can purchase the above labs/immunity tests for the cheapest price in
                                        California
                                        at:</p>
                                    <p class="less-line-height">
                                        <b>Lab test links:</b> <a :href="checkoutLink" target="_blank">Click
                                            here</a>
                                    </p>
                                    <p class="less-line-height"><b>Immunity test links:</b> <a :href="checkoutLink"
                                            target="_blank">Click
                                            here</a></p>
                                </div>
                            </div>
                        </n-gi>
                    </n-grid>
                </n-card>
            </div>
        </n-gi>
        <n-gi
            style="padding-left: 2rem; padding-right: 2rem; align-items: center; text-align: center; display: flex; justify-content: center;">
            <FAQ />
        </n-gi>
        <n-gi id="request-an-appointment">&nbsp;</n-gi>
        <n-gi
            style="padding-left: 2rem; padding-right: 2rem; align-items: center; text-align: center; display: flex; justify-content: center; background-color: transparent">
            <div style="min-height: 800px; padding-top: 3rem; padding-bottom: 3rem" class="request-appointment-section">
                <n-h1 class="block-case" style="padding-bottom: 1rem;">
                    Request An Appointment
                </n-h1>
                <RequestAppointment style="margin-top: -5%" />
            </div>
        </n-gi>
    </n-grid>
</template>

<script setup lang="js">
import { computed, nextTick, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";

import AirDatepicker from 'air-datepicker';
import localeEn from 'air-datepicker/locale/en';

import '@vuepic/vue-datepicker/dist/main.css';
import { Mail24Filled } from "@vicons/fluent";

import MiscService from "../services/misc.service";
import RequestAppointment from "../components/RequestAppointment.vue";
import ServiceLocationMapView from '../components/ServiceLocationMapView.vue';
import Reviews from "../components/Reviews.vue";
import FAQ from "../components/FAQ.vue";
import { isMobile, embeddedLanguageVideoMap, languageVerboseMap } from "@/utils";
import { c } from "naive-ui";


const store = useStore();

const instructionVideo = ref("https://www.youtube.com/embed/fKrnWd7LwwI?rel=0")
const birthDate = ref();
const chosenAgeGroup = ref("");
const emailSent = ref(false);
const formRef = ref(null);
const formValue = ref({
    user: {
        firstName: "",
        middleName: "",
        lastName: "",
        dateOfBirth: "",
        emailId: "",
        contactNumber: "",
    }
})
const birthDay = ref();
const birthMonth = ref();
const birthYear = ref();

const validateMessages = ref({
    messages: {
        required: 'This field is required!',
    }
})

const rules = ref({
    user: {
        firstName: {
            required: true,
            trigger: "blur",
            validator: (rule, value) => {
                if (!value) {
                    return new Error("Please enter your first name");
                }
            }
        },
        middleName: {
            required: false,
            trigger: "blur"
        },
        lastName: {
            required: false,
            trigger: "blur"
        },
        emailId: {
            required: true,
            trigger: "blur",
            validator: (rule, value) => {
                if (!value) {
                    return new Error("Email is required");
                }
                const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
                if (!emailRegex.test(value)) {
                    return new Error("Please enter a valid email address");
                }
                return true; // Validation passed                
            }
        },
        contactNumber: {
            required: true,
            trigger: "blur",
            validator: (rule, value) => {
                if (!value) {
                    return new Error("Please enter your contact number");
                }
                const contactRegex = /^[0-9]{10}$/;
                if (!contactRegex.test(value)) {
                    return new Error("Please enter a valid contact number");
                }
                return true; // Validation passed
            }
        },
        firsName: {
            required: true
        },
    }
})

const currentLanguage = computed(() => store.getters.currentLanguage)

onMounted(() => {
    new AirDatepicker('#dob', {
        // inline: true,
        locale: {
            days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            daysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            daysMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
            months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            today: 'Today',
            clear: 'Clear',
            dateFormat: 'MM/dd/yyyy',
            timeFormat: 'hh:ii aa',
            firstDay: 0
        },
        onSelect: (a) => {
            birthDate.value = a.date;
            calculateAge();
        },
        onShow: () => {
            // document.querySelector(".air-datepicker").style.width = document.querySelector("#dob").style.width;
        }
    });
    const referenceInput = document.getElementById("contact-number")
    const datePickerTrigger = document.querySelector("#dob")
    const datePickerContainer = document.querySelector(".dob-picker")

    datePickerContainer.style.width = `${referenceInput.offsetWidth}px`;

    datePickerTrigger.addEventListener("click", resizeAirDatePicker);
    datePickerTrigger.addEventListener("focus", resizeAirDatePicker);

    nextTick(() => {
    })
})

const resizeAirDatePicker = () => {
    setTimeout(() => {
        const referenceInput = document.getElementById("contact-number")
        // Get the width of input box B
        const referenceWidth = referenceInput.offsetWidth;

        // Select the dynamically added calendar popup
        const datePicker = document.querySelector(".air-datepicker.-bottom-left-.-from-bottom-.-active-");
        // Check if the date picker exists
        if (datePicker) {
            // Set the width of the date picker to match the input box
            datePicker.style.width = `${referenceWidth}px`;
        }
    }, 10);
}

const calculateAge = () => {
    const enteredBirthDate = new Date(birthDate.value);
    const today = new Date();

    let age = today.getFullYear() - enteredBirthDate.getFullYear();
    const monthDifference = today.getMonth() - enteredBirthDate.getMonth();

    // Adjust age if the current date is before the birthday in the current year
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < enteredBirthDate.getDate())) {
        age--;
    }

    const birthYear = enteredBirthDate.getFullYear();
    let ageGroup;

    if (birthYear >= 1957 && age >= 65) {
        // Age group for people 65 or older but born in or after 1957
        ageGroup = 'age-group-8';
    } else if (birthYear < 1957) {
        // Age group for those born before 1957
        ageGroup = 'age-group-9';
    } else {
        // Further breakdown of age groups
        switch (true) {
            case (age >= 60 && age <= 64):
                ageGroup = 'age-group-7';
                break;
            case (age >= 45 && age <= 59):
                ageGroup = 'age-group-6';
                break;
            case (age >= 25 && age <= 44):
                ageGroup = 'age-group-5';
                break;
            case (age >= 18 && age <= 24):
                ageGroup = 'age-group-4';
                break;
            case (age >= 11 && age <= 17):
                ageGroup = 'age-group-3';
                break;
            case (age >= 7 && age <= 10):
                ageGroup = 'age-group-2';
                break;
            case (age >= 6 && age < 7):
                ageGroup = 'age-group-10';
                break;
            case (age >= 2 && age <= 5):
                ageGroup = 'age-group-1';
                break;
            case (age < 2):
                ageGroup = 'age-group-0';
                break;
            default:
                ageGroup = 'age-group-unknown'; // In case age doesn't match any of the cases
        }
    }
    chosenAgeGroup.value = ageGroup;
}


const triggerConversation = async () => {
    if (document.getElementById("dob").value.length === 0) {
        store.dispatch("updateAppMessage", { msg: "Please enter date of birth.", type: "error" })
        return
    }
    formRef.value?.validate((error) => {
        if (error) {

        } else {
            store.dispatch("updateIsLoading", true);
            emailSent.value = true;
            setTimeout(() => {
                document.getElementById("required-documents-card").scrollIntoView({ behavior: "smooth" });
            }, 50);

            setTimeout(async () => {
                // document.getElementById("lala").innerHTML = document.getElementById("required-documents-card").innerHTML
                const response = await MiscService.triggerConversation({
                    age_group: chosenAgeGroup.value,
                    first_name: formValue.value.user.firstName,
                    middle_name: formValue.value.user.middleName,
                    last_name: formValue.value.user.lastName,
                    contact_number: formValue.value.user.contactNumber,
                    date_of_birth: birthDate.value,
                    to: formValue.value.user.emailId,
                    content: document.getElementById("required-documents-card").innerHTML,
                    preferred_language: languageVerboseMap[currentLanguage.value]
                });
                store.dispatch("updateIsLoading", false);
                if (response.success) {
                    store.dispatch("updateAppMessage", { msg: "All the details were successfully sent over email.", type: "success" })
                } else {
                    store.dispatch("updateAppMessage", { msg: "An internal error occured. Please try again.", type: "error" })
                }
            }, 200);
        }
    })

}

const scrollToAppointmentSection = () => {
    const appointmentSection = document.getElementById("request-an-appointment");
    appointmentSection.scrollIntoView({ behavior: "smooth" });
}

const checkoutLink = `${import.meta.env.VITE_APP_URL}/pre-checkout?lang=${currentLanguage.value}`

</script>

<style>
.three-steps-list {
    text-align: left;
}

.n-list.three-steps-list .n-list-item {
    text-align: left;
    font-size: 18px;
    font-weight: 500;
    line-height: 0.5rem;
}

.n-list.three-steps-list .n-list-item .n-list-item__divider {
    display: none;
}

.n-list.three-steps-list .n-list-item a {
    font-weight: 800;
}

.section-4 {
    width: 500px;
    height: 100%
}

.video-iframe {
    width: 560px;
    height: 315px;
}

.faq-section {
    text-align: left;
    width: 800px;
}

.faq-section .n-collapse-item__header-main {
    font-size: 20px;
    text-align: left;
}

.faq-section .n-collapse-item__content-inner,
.n-p {
    font-size: 18px;
    text-align: left;
    color: rgb(95, 93, 93);
}

.less-line-height {
    margin-top: 2px;
    margin-bottom: 2px;
}

.no-bold {
    font-size: 15px;
}

.no-bold h4 {
    margin-top: 2.2rem;
    margin-bottom: 2px;
}

.n-input .n-input__prefix {
    color: rgb(186, 184, 184);
}

.request-appointment-section {
    width: 800px;
}

.appointment-banner {
    text-align: center;
    background-color: black;
    color: white;
}

.appointment-banner .n-text {
    color: white;
}

.review-section {
    height: 750px;
    width: 100%
}

.dob-picker {
    text-align: center;
    /* width: 300px; */
}

.review-gi {
    padding-top: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: center;
}

.required-documents-card-gi {
    padding-left: 5rem;
        padding-right: 5rem;
        width: 90%;
        /* max-width: 800px; */
}

#dob {
    background-color: transparent;
    color: black;
}
.appointment-banner .n-text {
    text-transform: uppercase;
    font-size: 20px;
}

.appointment-banner-gi {
    padding-bottom: 2rem;
}

@media(max-width: 600px) {
    .appointment-banner .n-alert-body__content .n-text {
        text-transform: uppercase;
        font-size: 18px;
    }

    .section-4 {
        width: 100%;
        height: 100%;
        padding-left: 2 rem;
        padding-right: 2 rem;
    }

    .faq-section {
        text-align: left;
        max-width: 300px;
    }

    .video-iframe {
        width: 300px;
        height: 200px;
    }

    .review-section {
        height: 700px;
        width: 100%
    }

    .lms {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .dob-picker {
        text-align: center;
        width: 292px;
        flex-flow: wrap;
    }

    .review-gi {
        padding-top: 3rem;
        padding-left: 1rem;
        padding-right: 1rem;
        align-items: center;
        text-align: center;
        display: flex;
        justify-content: center;
    }

    .appointment-banner-gi {
        margin-top: 2rem;
        margin-bottom: 3rem;
    }

    .required-documents-card-gi {
        padding-left: 1rem;
        padding-right: 1rem;
        width: 100%;
    }

    .n-list.three-steps-list .n-list-item {
        padding-bottom: 0px;
        text-align: left;
        font-size: 17.5px;
        font-weight: 500;
        line-height: 1.2rem;
    }
}
</style>