<template>
    <n-space justify="center" style="padding-top: 2rem; padding-left: 2rem; padding-right: 2rem;">
        <n-h1>Terms of Service</n-h1>
    </n-space>
    <n-grid cols="1" item-responsive>
        <n-gi span="1" class="content-gi">
            <n-h2>Welcome to Ask For Cure!</n-h2>
            <n-p>
                Ask For Cure LLC (d/b/a Ask For Cure) (“Ask For Cure,” “we,” “us,” “our”)(“Ask For Cure,” “we,” “us,”
                “our”) provides its services (described below) to you (“you”) through its website located at
                www.askforcure.com (the “Site”) and through its mobile applications (the “App”), and through its related
                services, content, tools, widgets, software, API(s), and/or other product(s), service(s), data, or
                information supplied to you by Ask For Cure (collectively, including any new features and applications,
                and the Site and the App, the “Service(s)”), subject to the following Terms of Service (as amended from
                time to time, the “Terms of Service”).
            </n-p>
            <n-p>
                USE OF OUR SERVICES IS NOT APPROPRIATE FOR EMERGENCIES. IF YOU THINK YOU HAVE A MEDICAL OR MENTAL HEALTH
                EMERGENCY, OR IF AT ANY TIME YOU ARE CONCERNED ABOUT YOUR CARE OR TREATMENT, CALL 911 OR GO TO THE
                NEAREST OPEN CLINIC OR EMERGENCY ROOM. This agreement is the user agreement (the “Agreement”) for Ask
                For Cure. This Agreement describes the terms and conditions applicable to your use of our services
                available under the domain and sub-domains of the Site. If you do not agree to be bound by the terms and
                conditions of this Agreement, do not access the Site or use the Services. As discussed below, we reserve
                the right, at our sole discretion, to change or modify portions of these Terms of Service at any time.
                Your continued use of the Service after the date any such changes become effective constitutes your
                acceptance of the new Terms of Service. In addition, when using certain services, you may be subject to
                any additional terms and conditions applicable to you as a Ask For Cure user, including, without
                limitation, the Privacy Policy located at <n-a
                    href="https://www.askforcure.com/privacy-policy">https://www.askforcure.com/privacy-policy</n-a>
                (“Privacy Policy”). You
                must read, agree with and accept all of the terms and conditions contained in this Agreement and the
                Privacy Policy, which include those terms and conditions expressly set out below and those incorporated
                by reference, before you may use the Site.
            </n-p>
            <n-p>
                PLEASE READ THE FOLLOWING CAREFULLY AS IT CONTAINS AN AGREEMENT TO ARBITRATE AND OTHER IMPORTANT
                INFORMATION REGARDING YOUR LEGAL RIGHTS, REMEDIES, AND OBLIGATIONS. THE AGREEMENT TO ARBITRATE REQUIRES
                (WITH LIMITED EXCEPTION) THAT YOU SUBMIT CLAIMS YOU MAY HAVE AGAINST US TO BINDING AND FINAL
                ARBITRATION, AND FURTHER (1) YOU WILL ONLY BE PERMITTED TO PURSUE CLAIMS AGAINST ASK FOR CURE ON AN
                INDIVIDUAL BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS OR REPRESENTATIVE ACTION OR
                PROCEEDING, (2) YOU WILL ONLY BE PERMITTED TO SEEK RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND
                DECLARATORY RELIEF) ON AN INDIVIDUAL BASIS, AND (3) YOU MAY NOT BE ABLE TO HAVE ANY CLAIMS YOU HAVE
                AGAINST US RESOLVED BY A JURY OR IN A COURT OF LAW. BY USING THIS SITE, YOU SIGNIFY YOUR ACCEPTANCE OF
                THESE TERMS OF SERVICE. IF YOU DO NOT AGREE WITH ANY OF THE TERMS AND CONDITIONS CONTAINED IN THE TERMS
                OF SERVICE, PLEASE DO NOT USE THESE SERVICES.
            </n-p>
            <n-p>
                <b>1. Access Rights and Use of the Services</b>
            </n-p>
            <n-p>
                Services Description: Ask For Cure is a platform that facilitates the marketing and sale of health care
                services by Dr. Vinit Agrawal to patients, including you (“you” or “Patient(s)”) searching for health
                care services and Providers thereof, prescription services as described herein, and the scheduling of
                and payment for health care services and/or prescription services by you. Users of the Site shall be
                considered as either “Guests” or “Members.” Guests are allowed access to a minimal level of Services
                available on the Site, and access to some features may be limited. To benefit from all of the Services
                offered by Ask For Cure and access additional features that are not otherwise available to Guests, you
                must register as a Member and provide certain information about yourself, which you permit Ask For Cure
                to use and disclose as provided in our Privacy Policy. A user of the Site may also be classified by the
                Site as an “Inactive Member.” An Inactive Member is a user of the Site who was at one time a Member but
                now no longer has access to additional features or Services.
            </n-p>
            <n-p>
                Your Registration Obligations: You may be required to register with Ask For Cure in order to access and
                use certain features of the Service and become a Member. If you choose to register for the Service, you
                agree to provide and maintain true, accurate, current and complete information about yourself as
                prompted by the Service’s registration form. Registration data and certain other information about you
                are governed by our Privacy Policy. If you are under 18 years of age, you are not authorized to use the
                Service, with or without registering. Our Service is currently only available to individuals who are
                located in states in which we offer the Service, are at least eighteen (18) years of age or older, or at
                least the age of majority in their jurisdiction of residence, if higher than eighteen (18), and who have
                accepted this Agreement. By visiting, accessing, registering with or using the Service, you are (a)
                representing and warranting to us that you satisfy all of these eligibility requirements, (b)
                representing and warranting that when you use the Service to consult with Dr. Vinit Agrawal, you are
                located in the same state as the address you provide in your intake form, (c) agreeing to comply with
                all applicable laws in visiting, accessing, registering with our using the service, and (d) agreeing
                that you will only use the Service for lawful purposes. Our Service is subject to state regulations and
                may change from time to time due to changes in applicable regulatory requirements.
            </n-p>
            <n-p>Member Account, Password and Security: You are responsible for maintaining the confidentiality of your
                password and account, if any, and are fully responsible for any and all activities that occur under your
                password or account. Ask For Cure is entitled to act on instructions received under your password. You
                agree to (a) immediately notify Ask For Cure of any unauthorized use of your password or account or any
                other breach of security, and (b) ensure that you exit from your account at the end of each session when
                accessing the Service. Ask For Cure is not responsible for any actions taken or transaction made to or
                from your accounts by any other party using your password, and Ask For Cure will not be liable for any
                loss or damage arising from your failure to comply with this Section.</n-p>
            <n-p>Modifications to Our Service: Ask For Cure reserves the right to modify or discontinue, temporarily or
                permanently, the Service (or any part thereof) with or without notice. You agree that Ask For Cure will
                not be liable to you or to any third party for any modification, suspension or discontinuance of the
                Service.</n-p>
            <n-p>
                General Practices Regarding Use and Storage of Your Information: You acknowledge that Ask For Cure may
                establish general practices and limits concerning use of the Service, including without limitation the
                maximum period of time that data or other content will be retained by the Service and the maximum
                storage space that will be allotted on Ask For Cure’s servers on your behalf. You agree that Ask For
                Cure has no responsibility or liability for the deletion or failure to store any data or other content
                maintained or uploaded by the Service. You acknowledge that Ask For Cure reserves the right to terminate
                accounts that are inactive for an extended period of time. You further acknowledge that Ask For Cure
                reserves the right to change these general practices and limits at any time, in its sole discretion,
                with or without notice.
            </n-p>
            <n-p>
                Mobile Services: The Service includes certain services that are available via a mobile device, including
                (i) the ability to upload content to the Service via a mobile device and (ii) the ability to browse the
                Service and the Site from a mobile device (collectively, the “Mobile Services”). To the extent you
                access the Service through a mobile device, your wireless service carrier’s standard charges, data rates
                and other fees may apply. In addition, downloading, installing, or using certain Mobile Services may be
                prohibited or restricted by your carrier, and not all Mobile Services may work with all carriers or
                devices. By using the Mobile Services, you agree that we may communicate with you regarding Ask For Cure
                and other entities by SMS, MMS, text message or other electronic means to your mobile device and that
                certain information about your usage of the Mobile Services may be communicated to us. In the event you
                change or deactivate your mobile telephone number, you agree to promptly update your Ask For Cure
                account information to ensure that your messages are not sent to the person that acquires your old
                number.
            </n-p>
            <n-p>
                <b>2. Electronic Communications</b>
            </n-p>
            <n-p>
                When you visit the Site or send e-mails to us, you are communicating with us electronically and you
                consent to receive communications from us electronically. We will communicate with you by e-mail or by
                posting notices on this Site. You agree that all agreements, notices, disclosures and other
                communications that we provide to you electronically satisfy any legal requirement that such
                communications be in writing.
            </n-p>
            <n-p>By using the Site, you acknowledge and agree that Ask For Cure will send Member e-mails relating to
                your Member account and Ask For Cure promotions. You can opt out of your subscription to Ask For Cure ’s
                e-mail services at any time by sending us an email at <n-a
                    href="mailto:support@askforcure.com">support@askforcure.com</n-a>. </n-p>
            <n-p>
                By using the Site and opting to receive emails, text messages, and electronic communications, you
                acknowledge and agree that Ask For Cure will be transmitting certain of your protected health
                information (“PHI”) as that term is defined under HIPAA, electronically. You acknowledge and agree that
                while Ask For Cure takes commercially reasonable steps to protect the privacy and security of your PHI,
                no system is completely secure. You may choose not to receive emails or text messages any time by
                unsubscribing.
            </n-p>
            <n-p>You acknowledge and agree that you are solely responsible for providing Ask For Cure with accurate
                contact information, including your mobile device number and email address, where we may send
                communications containing your PHI. Ask For Cure is not responsible for user errors and omissions.</n-p>
            <n-p><b>
                    3. Your Use of the Services
                </b></n-p>
            <n-p>
                You agree to use the Services only to help you find a health care provider for yourself or another
                person. You are solely responsible for all images, information, data, text, messages or other materials
                (“content”) that you upload, post, publish or display (hereinafter, “upload”) or email or otherwise use
                via the Service. You will ensure that all content you upload or share complies with all applicable laws,
                rules and regulations, is appropriate and non-offensive, and that you have all necessary rights to use,
                share, and/or upload such content, without infringing any third party rights. Ask For Cure reserves the
                right to investigate and take appropriate legal action against anyone who, in Ask For Cure’s sole
                discretion, violates this provision, including without limitation, removing the offending content from
                the Service, suspending or terminating the account of such violators and reporting you to the law
                enforcement authorities. You agree to not use the Service in a manner that Ask For Cure determines, in
                its sole discretion, is illegal or improper.
            </n-p>
            <n-p>
                Fees: Ask For Cure does not accept Medicare, Medicaid, or any other third-party insurance. In the event
                you choose to use the Services to schedule and pay out of pocket for health care services, you will be
                required to provide Ask For Cure or Ask For Cure's financing partner with information regarding your
                credit card or other payment instrument. You represent and warrant to Ask For Cure that such information
                is true and that you are authorized to use the payment instrument. You will promptly update your account
                information with any changes (for example, a change in your billing address or credit card expiration
                date) that may occur. You agree to pay Ask For Cure the amount that is specified in the payment plan in
                accordance with the terms of such plan and this Terms of Service. If you dispute any charges you must
                let Ask For Cure know within sixty (60) days after the date that Ask For Cure charges you. You shall be
                responsible for all taxes associated with the Services.
            </n-p>
            <n-p>
                Further, we have no control over, and cannot guarantee the availability of, any health care provider at
                any particular time. Ask For Cure is not liable for any cancelled or unfulfilled appointments, or any
                injury or loss resulting from the foregoing, or for any other injury or loss related to your use of the
                Site or Services.
            </n-p>
            <n-p>
                Cancellation: Ask For Cure’s cancellation policy makes booking, cancelling, and rescheduling easy. Ask
                For Cure will honor a full refund for any of our visits when cancelled within the following time
                constraints:
            </n-p>
            <n-p>

                <ul>
                    <li>Virtual visits: cancel up to 3 hours before the scheduled visit</li>
                    <li>In-person visits: cancel up to 24 hours before the scheduled visit</li>
                </ul>
                We are unable to offer refunds for the following:
                <ul>
                    <li>No-shows or missed appointments</li>
                    <li>Refund requests due to not receiving a prescription or disagreement with the clinical outcome of
                        the
                        visit</li>
                    <li>Any request made 10 days after the original purchase date</li>
                </ul>
                <n-text>If you do make a same-day appointment by mistake and need to cancel, Ask For Cure will refund
                    you if
                    you request a cancellation within one hour of completing the purchase.</n-text>
            </n-p>
            <n-p>
                US Use Only: Ask For Cure services are only intended for users in the United States. Your information
                may be transmitted to third parties in other countries to help provide the services. This Service is
                hosted in the United States and are intended only for users located in the United States. Ask For Cure
                makes no representation that the materials are appropriate or available for use outside the United
                States. If you access our Service from outside the United States, you will be responsible for compliance
                with all local laws. You agree to comply with all laws and regulations applicable to your use of our
                Services.
            </n-p>
            <n-p>
                Note also that information submitted by you to us may be transmitted to and shared with third parties
                located in other countries, in order to provide Services to you. These Terms of Service and any separate
                agreements whereby we provide you Services shall be governed by and construed in accordance with the
                laws of California, United States.
            </n-p>
            <n-p>
                The Service is designed for and intended for Patients and/or other users in the United States only. We
                make no representation that the information and services provided on the Service are applicable to,
                appropriate for, or available to Patients and/or other users in locations outside the United States.
                Accessing the Service from territories where the content is illegal is prohibited. If you choose to
                access the site from a location outside the U.S., you do so on your own initiative, without our consent,
                and you are responsible for compliance with local laws.
            </n-p>
            <n-p>
                Commercial Use: Unless otherwise expressly authorized herein or in the Service, you agree not to
                display, distribute, license, perform, publish, reproduce, duplicate, copy, create derivative works
                from, modify, sell, resell, exploit, transfer or upload for any commercial purposes, any portion of the
                Service, use of the Service, or access to the Service. The Service is for your personal use only.
            </n-p>
            <n-p>
                Prescription Services: Medication is prescribed at the discretion of Dr. Vinit Agrawal and only if
                medically necessary. Ask For Cure makes no guarantees that you will get a prescription. The You agree
                that prescriptions are for your personal use. You also agree to read all the information and labels that
                accompany your prescription(s) and to contact a pharmacist or your healthcare provider with questions.
                Please note that Dr. Vinit Agrawal does not prescribe controlled substances as part of virtual (online)
                visits.
            </n-p>
            <n-p>
                <b>4. Intellectual Property Rights</b>
            </n-p>
            <n-p>
                Service Content, Software and Trademarks: You acknowledge and agree that the Service may contain content
                or features (“Service Content”) that are protected by copyright, patent, trademark, trade secret or
                other proprietary rights and laws. Except as expressly authorized by Ask For Cure, you agree not to
                modify, copy, frame, scrape, rent, lease, loan, sell, distribute or create derivative works based on the
                Service or the Service Content, in whole or in part, except that the foregoing does not apply to your
                own User Content (as defined below) that you legally upload to the Service. In connection with your use
                of the Service you will not engage in or use any data mining, robots, scraping or similar data gathering
                or extraction methods. If you are blocked by Ask For Cure from accessing the Service (including by
                blocking your IP address), you agree not to implement any measures to circumvent such blocking (e.g., by
                masking your IP address or using a proxy IP address). Any use of the Service or the Service Content
                other than as specifically authorized herein is strictly prohibited. The technology and software
                underlying the Service or distributed in connection therewith are the property of Ask For Cure, our
                affiliates and our partners (the “Software”). You agree not to copy, modify, create a derivative work
                of, reverse engineer, reverse assemble or otherwise attempt to discover any source code, sell, assign,
                sublicense, or otherwise transfer any right in the Software. Any rights not expressly granted herein are
                reserved by Ask For Cure.
            </n-p>
            <n-p>
                The Ask For Cure name and logos are trademarks and service marks of Ask For Cure (collectively the “Ask
                For Cure Trademarks”). Other company, product, and service names and logos used and displayed via the
                Service may be trademarks or service marks of their respective owners who may or may not endorse or be
                affiliated with or connected to Ask For Cure. Nothing in this Terms of Service or the Service should be
                construed as granting, by implication, estoppel, or otherwise, any license or right to use any of Ask
                For Cure Trademarks displayed on the Service, without our prior written permission in each instance. All
                goodwill generated from the use of Ask For Cure Trademarks will inure to our exclusive benefit.
            </n-p>
            <n-p>
                User Content Transmitted Through the Service: With respect to the content or other materials you upload
                through the Service or share with other users or recipients (collectively, “User Content”), you
                represent and warrant that you own all right, title and interest in and to such User Content, including,
                without limitation, all copyrights and rights of publicity contained therein. By voluntarily providing
                us with User Content, which may include Personal Information (as defined in the Privacy Policy), you are
                consenting to our use of Personal Information in accordance with these Terms of Service and our Privacy
                Policy. You also agree that any User Content you submit through the Service that is communicated to our
                partners, affiliates, and medical providers will be accurate and complete. By uploading any User Content
                you hereby grant and will grant Ask For Cure and its affiliated companies a nonexclusive, worldwide,
                royalty free, fully paid up, transferable, sublicensable, perpetual, irrevocable license to copy,
                display, upload, perform, distribute, store, modify and otherwise use your User Content in connection
                with the operation of the Service, the promotion, advertising or marketing thereof, the improvement of
                the Service, and as aggregated and/or de-identified for any lawful purpose in any form, medium or
                technology now known or later developed.
            </n-p>
            <n-p>
                You acknowledge and agree that any questions, comments, suggestions, ideas, feedback or other
                information about the Service (“Submissions”), provided by you to Ask For Cure are non-confidential and
                Ask For Cure will be entitled to the unrestricted use and dissemination of these Submissions for any
                purpose, commercial or otherwise, without acknowledgment or compensation to you.
            </n-p>
            <n-p>
                You acknowledge and agree that Ask For Cure may preserve content and may also disclose content if
                required to do so by law or in the good faith belief that such preservation or disclosure is reasonably
                necessary to: (a) comply with legal process, applicable laws or government requests; (b) enforce these
                Terms of Service; (c) respond to claims that any content violates the rights of third parties; or (d)
                protect the rights, property, or personal safety of Ask For Cure, its users and the public. You
                understand that the technical processing and transmission of the Service, including your content, may
                involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical
                requirements of connecting networks or devices.
            </n-p>
            <n-p>
                <b>7. Our Obligations</b>
            </n-p>
            <n-p>
                To provide and process the services to you, Ask For Cure shares your personally identifiable information
                with our authorized service providers that perform certain services or functions on our behalf. Ask For
                Cure may be required by law to disclose information you have provided in using any of the services on
                the Site. Ask For Cure may also disclose information where someone poses a threat to Ask For Cure’s
                interests (such as user fraud) or whose activities could bring harm to others. In addition, it may be
                necessary in the reasonable opinion of officials of Ask For Cure that certain information be provided to
                third parties, such as law enforcement authorities or governing bodies for a particular health care
                provider. In the event that Ask For Cure receives a court order to disclose information on the Site
                about you or your activities on the Site, you agree that such information may be disclosed.
            </n-p>
            <n-p>
                You may request that we update or change the information you provided to us or to receive a copy of the
                information we have stored by emailing us at <n-a
                    href="mailto:support@askforcure.com">support@askforcure.com</n-a> or by updating the information in
                your Member profile. We will make every reasonable effort to honor your wishes, or provide you with an
                explanation of why we cannot make the changes requested. We may request proof of identity before
                responding to any requests.
            </n-p>
            <n-p>
                <b>8. Third Party Websites</b>
            </n-p>
            <n-p>
                The Service may provide, or third parties may provide, links or other access to other sites and
                resources on the Internet. Ask For Cure has no control over such sites and resources and Ask For Cure is
                not responsible for and does not endorse such sites and resources. You further acknowledge and agree
                that Ask For Cure will not be responsible or liable, directly or indirectly, for any damage or loss
                caused or alleged to be caused by or in connection with use of or reliance on any content, events, goods
                or services available on or through any such site or resource.
            </n-p>
            <n-p>
                <b>9. Indemnity and Release</b>
            </n-p>
            <n-p>
                You agree to release, indemnify, defend and hold Ask For Cure and its affiliates and its and their
                officers, employees, directors and agents (collectively, “Indemnitees”) harmless from any from any and
                all losses, damages, liabilities, fines, fees, penalties, costs, expenses, including reasonable
                attorneys’ fees, arising out of or related to any allegations, rights, claims, actions of any kind
                arising out of or related to: (i) injury (including death) or damage to property caused by your or your
                agents’ action or inaction; (ii) your use of the Service, (iii) any User Content or any other content or
                materials you supply directly or indirectly, (iv) your connection to the Service, (v) your violation of
                these Terms of Service or this Agreement, or (vi) your violation of any rights of another. If you are a
                California resident, you waive California Civil Code Section 1542, which says: “A general release does
                not extend to claims which the creditor does not know or suspect to exist in his favor at the time of
                executing the release, which if known by him must have materially affected his settlement with the
                debtor.” If you are a resident of another jurisdiction, you waive any comparable statute or doctrine.
                ANY OBLIGATION TO INDEMNIFY AN INDEMNITEE FOR ANY LOSS, LIABILITY OR EXPENSE ARISING FROM ANY NEGLIGENT,
                GROSSLY NEGLIGENT, RECKLESS, WILLFUL, FRAUDULENT OR INTENTIONAL CONDUCT COMMITTED BY ANY INDEMNITEE MAY
                NOT BE ENFORCEABLE AGAINST ANY VISITOR OF THE SERVICES TO THE EXTENT APPLICABLE LAW SO REQUIRES, SUCH AS
                FROM CERTAIN JURISDICTIONS (SUCH AS FROM NEW JERSEY).
            </n-p>
            <n-p>
                <b>10. Disclaimer of Warranties</b>
            </n-p>
            <n-p>
                YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. THE SERVICE IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE”
                BASIS. ASK FOR CURE EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED OR
                STATUTORY, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.ASK FOR CURE MAKES NO WARRANTY THAT (I) THE SERVICE WILL
                MEET YOUR REQUIREMENTS, (II) THE SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (III) THE
                RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICE WILL BE ACCURATE OR RELIABLE, OR (IV) THE
                QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, PROVIDERS, OR OTHER MATERIAL PURCHASED OR OBTAINED BY
                YOU THROUGH THE SERVICE WILL MEET YOUR EXPECTATIONS.
            </n-p>
            <n-p>
                <b>11. Limitation of Liability</b>
            </n-p>
            <n-p>
                YOU EXPRESSLY UNDERSTAND AND AGREE THAT ASK FOR CURE WILL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL,
                SPECIAL, CONSEQUENTIAL, EXEMPLARY DAMAGES, OR DAMAGES FOR LOSS OF PROFITS INCLUDING BUT NOT LIMITED TO,
                DAMAGES FOR LOSS OF GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF ASK FOR CURE HAS BEEN
                ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT
                LIABILITY OR OTHERWISE, INCLUDING IF RESULTING FROM: (I) THE USE OR THE INABILITY TO USE THE SERVICE;
                (II) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES RESULTING FROM ANY GOODS, DATA,
                INFORMATION OR SERVICES PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH
                OR FROM THE SERVICE; (III) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; (IV)
                STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE SERVICE (INCLUDING WITHOUT LIMITATION ANY PROVIDER); OR
                (V) ANY OTHER MATTER RELATING TO THE SERVICE. IN NO EVENT WILL ASK FOR CURE’S TOTAL LIABILITY TO YOU FOR
                ALL DAMAGES, LOSSES OR CAUSES OF ACTION EXCEED THE AMOUNT YOU HAVE PAID ASK FOR CURE IN THE LAST SIX (6)
                MONTHS, OR, IF GREATER, ONE HUNDRED DOLLARS ($100).
            </n-p>
            <n-p>
                SOME JURISDICTIONS DO NOT ALLOW THE DISCLAIMER OR EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR
                EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE ABOVE
                LIMITATIONS SET FORTH ABOVE MAY NOT APPLY TO YOU OR BE ENFORCEABLE WITH RESPECT TO YOU. IF YOU ARE
                DISSATISFIED WITH ANY PORTION OF THE SERVICE OR WITH THESE TERMS OF SERVICE, YOUR SOLE AND EXCLUSIVE
                REMEDY IS TO DISCONTINUE USE OF THE SERVICE.
            </n-p>
            <n-p>
                IF YOU ARE A USER FROM NEW JERSEY, THE FOREGOING SECTIONS TITLED “INDEMNIFICATION AND RELEASE”,
                “DISCLAIMER OF WARRANTIES” AND “LIMITATION OF LIABILITY” ARE INTENDED TO BE ONLY AS BROAD AS IS
                PERMITTED UNDER THE LAWS OF THE STATE OF NEW JERSEY. IF ANY PORTION OF THESE SECTIONS IS HELD TO BE
                INVALID UNDER THE LAWS OF THE STATE OF NEW JERSEY, THE INVALIDITY OF SUCH PORTION SHALL NOT AFFECT THE
                VALIDITY OF THE REMAINING PORTIONS OF THE APPLICABLE SECTIONS.
            </n-p>
            <n-p>
                <b>12. Dispute Resolution By Binding Arbitration</b>
            </n-p>
            <n-p>
                PLEASE READ THIS SECTION CAREFULLY AS IT AFFECTS YOUR RIGHTS.
            </n-p>
            <n-p>
                Agreement to Arbitrate: This Dispute Resolution by Binding Arbitration section is referred to in these
                Terms of Service as the Arbitration Agreement (“Arbitration Agreement”). You agree that any and all
                disputes or claims that have arisen or may arise between you and Ask For Cure, whether arising out of or
                relating to these Terms of Service (including any alleged breach thereof), the Services, any
                advertising, any aspect of the relationship or transactions between us, shall be resolved exclusively
                through final and binding arbitration, rather than a court, in accordance with the terms of this
                Arbitration Agreement, except that you may assert individual claims in small claims court, if your
                claims qualify. Further, this Arbitration Agreement does not preclude you from bringing issues to the
                attention of federal, state, or local agencies, and such agencies can, if the law allows, seek relief
                against us on your behalf. You agree that, by entering into these Terms of Service, you and Ask For Cure
                are each waiving the right to a trial by jury or to participate in a class action. Your rights will be
                determined by a neutral arbitrator, not a judge or jury. The Federal Arbitration Act governs the
                interpretation and enforcement of this Arbitration Agreement.
            </n-p>
            <n-p>
                Prohibition of Class and Representative Actions and Non-Individualized Relief: YOU AND ASK FOR CURE
                AGREE THAT EACH OF US MAY BRING CLAIMS AGAINST THE OTHER ONLY ON AN INDIVIDUAL BASIS AND NOT AS A
                PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE ACTION OR PROCEEDING. UNLESS BOTH YOU
                AND ASK FOR CURE AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE OR JOIN MORE THAN ONE PERSON’S OR
                PARTY’S CLAIMS AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A CONSOLIDATED, REPRESENTATIVE, OR CLASS
                PROCEEDING. ALSO, THE ARBITRATOR MAY AWARD RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY
                RELIEF) ONLY IN FAVOR OF THE INDIVIDUAL PARTY SEEKING RELIEF AND ONLY TO THE EXTENT NECESSARY TO PROVIDE
                RELIEF NECESSITATED BY THAT PARTY’S INDIVIDUAL CLAIM(S), EXCEPT THAT YOU MAY PURSUE A CLAIM FOR AND THE
                ARBITRATOR MAY AWARD PUBLIC INJUNCTIVE RELIEF UNDER APPLICABLE LAW TO THE EXTENT REQUIRED FOR THE
                ENFORCEABILITY OF THIS PROVISION.
            </n-p>
            <n-p>
                Pre-Arbitration Dispute Resolution: Ask For Cure is always interested in resolving disputes amicably and
                efficiently, and most customer concerns can be resolved quickly and to the customer’s satisfaction by
                emailing customer support at <n-a href="mailto:support@askforcure.com">support@askforcure.com</n-a>. If
                such efforts prove unsuccessful, a party who
                intends to seek arbitration must first send to the other, by certified mail, a written Notice of Dispute
                (“Notice”). The Notice to Ask For Cure should be sent to 485 N Indiana St, Porterville, CA 93257
                (“Notice Address”). The Notice must (i) describe the nature and basis of the claim or dispute and (ii)
                set forth the specific relief sought. If Ask For Cure and you do not resolve the claim within sixty (60)
                calendar days after the Notice is received, you or Ask For Cure may commence an arbitration proceeding.
                During the arbitration, the amount of any settlement offer made by Ask For Cure or you shall not be
                disclosed to the arbitrator until after the arbitrator determines the amount, if any, to which you or
                Ask For Cure is entitled.
            </n-p>
            <n-p>
                Arbitration Procedures: Arbitration will be conducted by a neutral arbitrator in accordance with the
                American Arbitration Association’s (“AAA”) rules and procedures, including the AAA’s Consumer
                Arbitration Rules (collectively, the “AAA Rules”), as modified by this Arbitration Agreement. For
                information on the AAA, please visit its website, <n-a
                    href="http://www.adr.org">http://www.adr.org</n-a>. Information about the AAA Rules
                and fees for consumer disputes can be found at the AAA’s consumer arbitration page,
                <n-a href="http://www.adr.org/consumer_arbitration">http://www.adr.org/consumer_arbitration</n-a>. If
                there is any inconsistency between any term of the AAA Rules
                and any term of this Arbitration Agreement, the applicable terms of this Arbitration Agreement will
                control unless the arbitrator determines that the application of the inconsistent Arbitration Agreement
                terms would not result in a fundamentally fair arbitration. The arbitrator must also follow the
                provisions of these Terms of Service as a court would. All issues are for the arbitrator to decide,
                including, but not limited to, issues relating to the scope, enforceability, and arbitrability of this
                Arbitration Agreement. Although arbitration proceedings are usually simpler and more streamlined than
                trials and other judicial proceedings, the arbitrator can award the same damages and relief on an
                individual basis that a court can award to an individual under the Terms of Service and applicable law.
                Decisions by the arbitrator are enforceable in court and may be overturned by a court only for very
                limited reasons.
            </n-p>
            <n-p>
                Unless Ask For Cure and you agree otherwise, any arbitration hearings will take place in a reasonably
                convenient location for both parties with due consideration of their ability to travel and other
                pertinent circumstances. If the parties are unable to agree on a location, the determination shall be
                made by AAA. If your claim is for $10,000 or less, Ask For Cure agrees that you may choose whether the
                arbitration will be conducted solely on the basis of documents submitted to the arbitrator, through a
                telephonic hearing, or by an in-person hearing as established by the AAA Rules. If your claim exceeds
                $10,000, the right to a hearing will be determined by the AAA Rules. Regardless of the manner in which
                the arbitration is conducted, the arbitrator shall issue a reasoned written decision sufficient to
                explain the essential findings and conclusions on which the award is based.
            </n-p>
            <n-p>
                Costs of Arbitration: Payment of all filing, administration, and arbitrator fees (collectively, the
                “Arbitration Fees”) will be governed by the AAA Rules, unless otherwise provided in this Arbitration
                Agreement. If the value of the relief sought is $75,000 or less, at your request, Ask For Cure will pay
                all Arbitration Fees. If the value of relief sought is more than $75,000 and you are able to demonstrate
                to the arbitrator that you are economically unable to pay your portion of the Arbitration Fees or if the
                arbitrator otherwise determines for any reason that you should not be required to pay your portion of
                the Arbitration Fees, Ask For Cure will pay your portion of such fees. In addition, if you demonstrate
                to the arbitrator that the costs of arbitration will be prohibitive as compared to the costs of
                litigation, Ask For Cure will pay as much of the Arbitration Fees as the arbitrator deems necessary to
                prevent the arbitration from being cost-prohibitive. Any payment of attorneys’ fees will be governed by
                the AAA Rules.
            </n-p>
            <n-p>
                Confidentiality: All aspects of the arbitration proceeding, and any ruling, decision, or award by the
                arbitrator, will be strictly confidential for the benefit of all parties.
            </n-p>
            <n-p>
                Severability: If a court or the arbitrator decides that any term or provision of this Arbitration
                Agreement (other than the subsection (b) titled “Prohibition of Class and Representative Actions and
                Non-Individualized Relief” above) is invalid or unenforceable, the parties agree to replace such term or
                provision with a term or provision that is valid and enforceable and that comes closest to expressing
                the intention of the invalid or unenforceable term or provision, and this Arbitration Agreement shall be
                enforceable as so modified. If a court or the arbitrator decides that any of the provisions of
                subsection (b) above titled “Prohibition of Class and Representative Actions and Non-Individualized
                Relief” are invalid or unenforceable, then the entirety of this Arbitration Agreement shall be null and
                void, unless such provisions are deemed to be invalid or unenforceable solely with respect to claims for
                public injunctive relief. The remainder of the Terms of Service will continue to apply.
            </n-p>
            <n-p>
                Future Changes to Arbitration Agreement: Notwithstanding any provision in these Terms of Service to the
                contrary, Ask For Cure agrees that if it makes any future change to this Arbitration Agreement (other
                than a change to the Notice Address) while you are a user of the Services, you may reject any such
                change by sending Ask For Cure written notice within thirty (30) calendar days of the change to the
                Notice Address provided above. By rejecting any future change, you are agreeing that you will arbitrate
                any dispute between us in accordance with the language of this Arbitration Agreement as of the date you
                first accepted these Terms of Service (or accepted any subsequent changes to these Terms of Service).
            </n-p>
            <n-p>
                <b>13. Termination</b>
            </n-p>
            <n-p>
                You agree that Ask For Cure, in its sole discretion, may suspend or terminate your account (or any part
                thereof) or use of the Service and remove and discard any content within the Service, for any reason,
                including, without limitation, for lack of use or if Ask For Cure believes that you have violated or
                acted inconsistently with the letter or spirit of these Terms of Service. Any suspected fraudulent,
                abusive or illegal activity that may be grounds for termination of your use of Service, may be referred
                to appropriate law enforcement authorities. Ask For Cure may also in its sole discretion and at any time
                discontinue providing the Service, or any part thereof, with or without notice and Ask For Cure may
                immediately deactivate or delete your account and all related information and files in your account
                and/or bar any further access to such files or the Service. Further, you agree that Ask For Cure will
                not be liable to you or any third party for any termination of your access to the Service.
            </n-p>
            <n-p>
                <b>14. General</b>
            </n-p>
            <n-p>
                These Terms of Service, in conjunction with the Privacy Policy, constitute the entire agreement between
                you and Ask For Cure and govern your use of the Service, superseding any prior agreements between you
                and Ask For Cure with respect to the Services. You also may be subject to additional terms and
                conditions that may apply when you use affiliate or third party services, third party content or third
                party software. These Terms of Service will be governed by the laws of the State of California without
                regard to its conflict of law provisions. With respect to any disputes or claims not subject to
                arbitration, as set forth above, you agree to submit to the personal and exclusive jurisdiction of the
                state and federal courts located within Porterville, California. The failure of Ask For Cure to exercise
                or enforce any right or provision of these Terms of Service will not constitute a waiver of such right
                or provision. If any provision of these Terms of Service is found by a court of competent jurisdiction
                to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the
                parties’ intentions as reflected in the provision, and the other provisions of these Terms of Service
                remain in full force and effect. You agree that regardless of any statute or law to the contrary, any
                claim or cause of action you may have arising out of or related to use of the Service or these Terms of
                Service must be filed within one (1) year after such claim or cause of action arose or be forever
                barred. A printed version of this agreement and of any notice given in electronic form will be
                admissible in judicial or administrative proceedings based upon or relating to this agreement to the
                same extent and subject to the same conditions as other business documents and records originally
                generated and maintained in printed form. You may not assign this Terms of Service without the prior
                written consent of Ask For Cure, but Ask For Cure may assign or transfer this Terms of Service, in whole
                or in part, without restriction. The section titles or headings in these Terms of Service are for
                convenience only and have no legal or contractual effect. Notices to you may be made via either email or
                regular mail. Notice to Ask For Cure must be in writing, sent via registered mail or nationally
                recognized overnight courier, with a duplicate copy to the legal department, with confirmation of
                delivery. The Service may also provide notices to you of changes to these Terms of Service or other
                matters by displaying notices or links to notices generally on the Service.
            </n-p>
            <n-p>
                We will not be deemed to be in breach of these terms or liable for any breach of these terms or our
                privacy policy due to any event or occurrence beyond our reasonable control, including without
                limitation, acts of God, terrorism, war, invasion, failures of any public networks, electrical
                shortages, disease, epidemic, pandemic, earthquakes or floods, civil disorder, strikes, fire or other
                disaster.
            </n-p>
            <n-p>
                <b>15. Your Privacy</b>
            </n-p>
            <n-p>
                We do not sell or rent your personal information to third parties for their marketing purposes without
                your explicit consent unless otherwise permitted, and we only use your information as described in the
                Privacy Policy. We view protection of users’ privacy as a very important community principle. We
                understand clearly that you and your information are one of our most important assets. Our current
                Privacy Policy is available on the Site at <n-a
                    href="https://askforcure.com/privacy-policy">https://www.askforcure.com/privacy-policy</n-a>. If you
                object to your
                information being transferred or used in this way, please do not use the Services.
            </n-p>
            <n-p>
                <b>16. Notice for California Users</b>
            </n-p>
            <n-p>Under California Civil Code Section 1789.3, users of the Service from California are entitled to the
                following specific consumer rights notice: The Complaint Assistance Unit of the Division of Consumer
                Services of the California Department of Consumer Affairs may be contacted in writing at 1625 North
                Market Blvd., Suite N 112, Sacramento, CA 95834, or by telephone at (916) 445-1254 or (800) 952-5210.
                You may contact us at Ask For Cure LLC, 485 N Indiana St, Porterville, CA 93257 or (318) 599-9399.</n-p>
            <n-p>
                <b>17. Questions? Suggestions?</b>
            </n-p>
            <n-p>
                Please email us at <n-a href="mailto:support@askforcure.com">support@askforcure.com</n-a> to report any
                violations of these Terms of Service or to pose
                any questions regarding these Terms of Service or the Service.
            </n-p>
            <n-p>
                <b>18. Tele-Medicine</b>
            </n-p>
            <n-p>
                Some of our Services may include a tele-health online solution that allows participating medical
                professionals and health care staff to communicate with their clients, customers and/or patients
                (collectively, “Patients”) to provide health care related services online and perform virtual house
                calls via Internet connection. We are not responsible for internet connectivity or lack thereof or
                problems or issues related to the use thereof.
            </n-p>
            <n-p>
                We do not issue prescriptions for substances controlled by any federal or state agency or other drugs
                that may be harmful because of their potential for abuse.
            </n-p>
            <n-p>
                You hereby certify that you are not a Medicare or Medicaid beneficiary. If you provide false or
                deceptive information to us, including without limitation regarding your Medicare or Medicaid enrollment
                status, we reserve the right to terminate all current or future use of the Services by you.
            </n-p>
            <n-p>
                BY USING THE SERVICES AND/OR AGREEING TO THESE TERMS, YOU AGREE AND CONSENT THAT YOU HAVE READ AND
                UNDERSTAND THE INFORMATION PROVIDED HEREIN, AND UNDERSTAND THE RISKS AND BENEFITS OF TELEMEDICINE, AND
                BY ACCEPTING THESE TERMS OF USE YOU HEREBY GIVE MY INFORMED CONSENT TO PARTICIPATE IN A TELEMEDICINE
                VISIT UNDER THE TERMS DESCRIBED HEREIN. YOU ARE CONSENTING TO RECEIVING CARE VIA THE SERVICE. THE SCOPE
                OF CARE WILL BE AT THE SOLE DISCRETION OF THE PROVIDER WHO IS TREATING YOU, WITH NO GUARANTEE OF
                DIAGNOSIS, TREATMENT, OR PRESCRIPTION. THE PROVIDER WILL DETERMINE WHETHER OR NOT THE CONDITION BEING
                DIAGNOSED AND/OR TREATED IS APPROPRIATE FOR A TELEHEALTH ENCOUNTER VIA THE SERVICE.
            </n-p>
            <n-p>
                <b>19. Changes to These Terms of Service</b>
            </n-p>
            <n-p>
                Ask For Cure reserves the right, at any time, to modify, alter, or update these Terms of Service by
                posting the modified, altered or updated terms on the Site or through other reasonable means, and you
                agree to be bound by such modifications, alterations or updates. If you do not agree to such
                modifications, alterations or updates, you should terminate your use of the Services. Any such changes
                will become effective when they are posted. Your continued use of any of the Services following the
                posting of notice of any changes in this Agreement shall constitute your acceptance of such
                modifications, alterations and updates.
            </n-p>
        </n-gi>
    </n-grid>
</template>
<style lang="css" scoped>
.content-gi {
    text-align: left;
    padding-left: 15rem;
    padding-right: 15rem;
    padding-bottom: 10rem;
}

@media(max-width: 600px) {
    .content-gi {
        text-align: center;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 10rem;
    }
}
</style>